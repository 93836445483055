import React from 'react';
import { ChevronLeft, Logout, Menu } from '@mui/icons-material';
import { Divider, IconButton, Drawer as MuiDrawer, Toolbar, styled, Button, Box } from '@mui/material';
import logo from '../../_assets/logo.png';
import ListItems from '../../components/ListItems';
import { AvatarFL } from '../../components/AvatarFL';
import { useAuthStore } from '../../store';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'fixed',
        whiteSpace: 'nowrap',
        width: open ? drawerWidth : theme.spacing(7),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        overflowX: 'hidden',
        backgroundColor: 'var(--primary-dark-color)',
        color: 'var(--white-color)',
        height: '100%',
        minHeight: '100vh',
        zIndex: 1200,
        overflowY: 'auto',
        [theme.breakpoints.up('sm')]: {
            width: open ? drawerWidth : theme.spacing(9),
        },
        [theme.breakpoints.down('sm')]: {
            width: open ? drawerWidth : theme.spacing(7),
        },
        display: 'flex',
        flexDirection: 'column',
    },
    '& .MuiSvgIcon-root': {
        fill: 'var(--white-color)',
    },
}));

const UserSection = ({ user, logout, open }) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: open ? 'flex-end' : 'center',
            flexGrow: 1,
            mb: open ? 2 : 0,
            pb: 2,
            height: 'auto',
        }}
    >
        <AvatarFL width="40px" height="40px" name={user.nome} />
        <Box
            sx={{
                mt: 2,
                flexDirection: open ? 'row' : 'column',
            }}
        >
            <Button edge="end" color="inherit" aria-label="logout user" onClick={logout}>
                <Logout />
            </Button>
        </Box>
    </Box>
);

export const DashboardDrawerMenu = ({ open, toggleDrawer }) => {
    const user = useAuthStore((state) => state.user);
    const logout = useAuthStore((state) => state.logout);

    return (
        <Drawer variant="permanent" open={open}>
            <Box
                sx={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '100%',
                    '@media (min-width: 890px) and (max-width: 940px)': {
                        minHeight: 'calc(100vh + 1px)',
                    },
                }}
            >
                <Toolbar
                    sx={{
                        pr: '24px',
                        bgcolor: 'var(--primary-dark-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: { xs: '48px', sm: '56px' },
                    }}
                >
                    <IconButton onClick={toggleDrawer}>{open ? <ChevronLeft /> : <Menu />}</IconButton>
                </Toolbar>
                {open && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1 }}>
                        <img src={logo} alt="logo" style={{ maxWidth: '100%' }} />
                    </Box>
                )}
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <ListItems open={open} />
                </Box>
                <Divider sx={{ backgroundColor: 'var(--primary-color)' }} />
                <UserSection user={user} logout={logout} open={open} />
            </Box>
        </Drawer>
    );
};
