import React, { useState } from 'react';
import { ArrowDownward, ArrowUpward, Delete, ModeEdit, ViewList } from '@mui/icons-material';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
    Drawer,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useError } from '../../contexts/ErrorContext';
import { useAuthStore } from '../../store';
import { apiDeleteCommunity } from '../../services';
import { VISIBILITY, TYPE } from '../../data/Interfaces';
import { RankingPeriodos } from './RankingPeriodos';

export const ComunidadesList = ({ comunidades, handleOrdenar, ordenacao, setUpdated }) => {
    const { showError } = useError();
    const user = useAuthStore((state) => state.user);
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedComunidade, setSelectedComunidade] = useState(null);

    const editarComunidade = (comunidade) => {
        navigate(`/comunidades/editar/${comunidade.id}`);
    };

    const excluirComunidade = async (id) => {
        if (!id) return;
        try {
            await apiDeleteCommunity(id);
            if (setUpdated) {
                setUpdated((prev) => !prev);
            }
        } catch (error) {
            showError(error);
        }
    };

    const isOrdenadoAsc = (campo) => {
        return ordenacao === `${campo}_asc`;
    };

    const isOrdenadoDesc = (campo) => {
        return ordenacao === `${campo}_desc`;
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#424242',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const iconStyle = {
        fontSize: '14px',
    };

    const renderTable = () => (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell
                            onClick={() => handleOrdenar('titulo')}
                            style={{
                                cursor: 'pointer',
                                fontWeight: isOrdenadoAsc('titulo') || isOrdenadoDesc('titulo') ? 'bold' : 'normal',
                            }}
                        >
                            Título
                            {isOrdenadoAsc('titulo') && <ArrowUpward style={iconStyle} />}
                            {isOrdenadoDesc('titulo') && <ArrowDownward style={iconStyle} />}
                        </StyledTableCell>
                        <StyledTableCell
                            onClick={() => handleOrdenar('cliente')}
                            style={{
                                cursor: 'pointer',
                                fontWeight: isOrdenadoAsc('cliente') || isOrdenadoDesc('cliente') ? 'bold' : 'normal',
                            }}
                        >
                            Cliente
                            {isOrdenadoAsc('cliente') && <ArrowUpward style={iconStyle} />}
                            {isOrdenadoDesc('cliente') && <ArrowDownward style={iconStyle} />}
                        </StyledTableCell>
                        <StyledTableCell
                            onClick={() => handleOrdenar('visibility')}
                            style={{
                                cursor: 'pointer',
                                fontWeight:
                                    isOrdenadoAsc('visibility') || isOrdenadoDesc('visibility') ? 'bold' : 'normal',
                            }}
                        >
                            Visibilidade
                            {isOrdenadoAsc('visibility') && <ArrowUpward style={iconStyle} />}
                            {isOrdenadoDesc('visibility') && <ArrowDownward style={iconStyle} />}
                        </StyledTableCell>
                        <StyledTableCell>Categorias</StyledTableCell>
                        <StyledTableCell>Ações</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {comunidades?.map((comunidade) => (
                        <TableRow key={comunidade.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                {comunidade.titulo}
                            </TableCell>
                            <TableCell>{comunidade.clienteNome}</TableCell>
                            <TableCell>{VISIBILITY[comunidade.visibility] || '-'}</TableCell>
                            <TableCell>
                                {comunidade.categorias?.length > 0
                                    ? comunidade.categorias.join(', ')
                                    : 'Sem categorias'}
                            </TableCell>
                            <TableCell align="right">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        gap: '2px',
                                        border: '1px solid #eaeaea',
                                        borderRadius: '4px',
                                        padding: '2px',
                                        width: 'fit-content',
                                        marginLeft: 'auto',
                                    }}
                                >
                                    <Button
                                        aria-label="Editar Comunidade"
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            editarComunidade(comunidade);
                                        }}
                                        sx={{
                                            color: 'var(--primary-color)',
                                            minWidth: '32px',
                                            padding: '4px',
                                        }}
                                    >
                                        <ModeEdit fontSize="small" />
                                    </Button>
                                    {user?.acesso === 1 && (
                                        <Button
                                            aria-label="Excluir Comunidade"
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    window.confirm(
                                                        'Tem certeza que deseja excluir esta comunidade? Esta ação não pode ser desfeita.',
                                                    )
                                                ) {
                                                    excluirComunidade(comunidade.id);
                                                }
                                            }}
                                            sx={{
                                                color: 'var(--error-color)',
                                                minWidth: '32px',
                                                padding: '4px',
                                            }}
                                        >
                                            <Delete fontSize="small" />
                                        </Button>
                                    )}
                                    {comunidade.ranking && (
                                        <Button
                                            aria-label="Ver Períodos do Ranking"
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedComunidade(comunidade.id);
                                                setOpenDrawer(true);
                                            }}
                                            sx={{
                                                color: 'var(--success-color)',
                                                minWidth: '32px',
                                                padding: '4px',
                                            }}
                                        >
                                            <ViewList fontSize="small" />
                                        </Button>
                                    )}
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="Lista de Comunidades">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: 'var(--grey-100-color)' }}>
                            <StyledTableCell
                                onClick={() => handleOrdenar('titulo')}
                                style={{
                                    cursor: 'pointer',
                                    fontWeight: isOrdenadoAsc('titulo') || isOrdenadoDesc('titulo') ? 'bold' : 'normal',
                                }}
                            >
                                Título
                                {isOrdenadoAsc('titulo') && <ArrowUpward style={iconStyle} />}
                                {isOrdenadoDesc('titulo') && <ArrowDownward style={iconStyle} />}
                            </StyledTableCell>
                            <StyledTableCell
                                onClick={() => handleOrdenar('clienteNome')}
                                style={{
                                    cursor: 'pointer',
                                    fontWeight:
                                        isOrdenadoAsc('clienteNome') || isOrdenadoDesc('clienteNome')
                                            ? 'bold'
                                            : 'normal',
                                }}
                            >
                                Cliente
                                {isOrdenadoAsc('clienteNome') && <ArrowUpward style={iconStyle} />}
                                {isOrdenadoDesc('clienteNome') && <ArrowDownward style={iconStyle} />}
                            </StyledTableCell>
                            <StyledTableCell
                                onClick={() => handleOrdenar('visibility')}
                                style={{
                                    cursor: 'pointer',
                                    fontWeight:
                                        isOrdenadoAsc('visibility') || isOrdenadoDesc('visibility') ? 'bold' : 'normal',
                                }}
                            >
                                Visibilidade
                                {isOrdenadoAsc('visibility') && <ArrowUpward style={iconStyle} />}
                                {isOrdenadoDesc('visibility') && <ArrowDownward style={iconStyle} />}
                            </StyledTableCell>
                            <StyledTableCell>Categorias</StyledTableCell>
                            <StyledTableCell>Ações</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {comunidades.map((comunidade) => (
                            <TableRow
                                key={comunidade.id}
                                hover
                                onClick={() => navigate(`/comunidades/editar/${comunidade.id}`)}
                                style={{ cursor: 'pointer' }}
                            >
                                <TableCell>
                                    <strong>{comunidade.titulo}</strong>
                                </TableCell>
                                <TableCell>{comunidade.clienteNome}</TableCell>
                                <TableCell>{VISIBILITY[comunidade.visibility] || '-'}</TableCell>
                                <TableCell>
                                    {comunidade.categorias?.length > 0
                                        ? comunidade.categorias.join(', ')
                                        : 'Sem categorias'}
                                </TableCell>
                                <TableCell align="right">
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            gap: '2px',
                                            border: '1px solid #eaeaea',
                                            borderRadius: '4px',
                                            padding: '2px',
                                            width: 'fit-content',
                                            marginLeft: 'auto',
                                        }}
                                    >
                                        <Button
                                            aria-label="Editar Comunidade"
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                editarComunidade(comunidade);
                                            }}
                                            sx={{
                                                color: 'var(--primary-color)',
                                                minWidth: '32px',
                                                padding: '4px',
                                            }}
                                        >
                                            <ModeEdit fontSize="small" />
                                        </Button>
                                        {user?.acesso === 1 && (
                                            <Button
                                                aria-label="Excluir Comunidade"
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (
                                                        window.confirm(
                                                            'Tem certeza que deseja excluir esta comunidade? Esta ação não pode ser desfeita.',
                                                        )
                                                    ) {
                                                        excluirComunidade(comunidade.id);
                                                    }
                                                }}
                                                sx={{
                                                    color: 'var(--error-color)',
                                                    minWidth: '32px',
                                                    padding: '4px',
                                                }}
                                            >
                                                <Delete fontSize="small" />
                                            </Button>
                                        )}
                                        {comunidade.ranking && (
                                            <Button
                                                aria-label="Ver Períodos do Ranking"
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedComunidade(comunidade.id);
                                                    setOpenDrawer(true);
                                                }}
                                                sx={{
                                                    color: 'var(--success-color)',
                                                    minWidth: '32px',
                                                    padding: '4px',
                                                }}
                                            >
                                                <ViewList fontSize="small" />
                                            </Button>
                                        )}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Drawer
                anchor="right"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: '600px',
                    },
                }}
            >
                <RankingPeriodos comunidade={selectedComunidade} />
            </Drawer>
        </>
    );
};
