import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNotificationStore } from '../../store';
import { generateLink } from '../../utils';
import { apiGetCampanha } from '../../services/apiGetCampanha';
import { apiGetCampanhas } from '../../services/apiGetCampanhas';
import { apiGetCampanhaItem } from '../../services/apiGetCampanhaItem';
import { isValidStorageValue, setSessionStorage } from '../../utils/handleStorage.utils';
import { useError } from '../../contexts/ErrorContext';
import CustomBadge from '../common/CustomBadge';

export const NotificationMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { notifications } = useNotificationStore((state) => ({
        notifications: state.notifications,
    }));

    const [links, setLinks] = useState([]);

    const { showError } = useError();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const fetchLinks = async () => {
            try {
                // Passo 1: Processar os itens da campanha para obter todos os IDs de campanha necessários
                const notificationsWithCampanhaIds = await Promise.all(
                    notifications.map(async (notification) => {
                        const notificationCopy = { ...notification };

                        if (
                            !isValidStorageValue(notificationCopy.idcampanha) &&
                            isValidStorageValue(notificationCopy.idcampanhaitem)
                        ) {
                            const campanhaItens = await apiGetCampanhaItem(notificationCopy.idcampanhaitem);
                            if (campanhaItens && campanhaItens.length > 0) {
                                notificationCopy.idcampanha = campanhaItens[0].campanha;
                            }
                        }
                        return notificationCopy;
                    }),
                );

                // Passo 2: Extrair todos os IDs de campanhas únicos
                const campanhaIds = notificationsWithCampanhaIds
                    .filter((notification) => isValidStorageValue(notification.idcampanha))
                    .map((notification) => notification.idcampanha);

                const uniqueCampanhaIds = [...new Set(campanhaIds)];

                // Passo 3: Buscar todas as campanhas de uma vez
                let campanhasData = [];
                if (uniqueCampanhaIds.length > 0) {
                    const idsQuery = uniqueCampanhaIds.join(',');
                    campanhasData = await apiGetCampanhas({ ids: idsQuery });

                    // Garantir que seja um array mesmo que tenha apenas um resultado
                    if (!Array.isArray(campanhasData)) {
                        campanhasData = [campanhasData];
                    }
                }

                // Passo 4: Mapear os dados da campanha para cada notificação
                const newLinks = await Promise.all(
                    notificationsWithCampanhaIds.map(async (notification) => {
                        if (notification.idcampanha) {
                            // Encontrar a campanha correspondente
                            const campanhaData = campanhasData.find((c) => c.id === notification.idcampanha);
                            if (campanhaData) {
                                notification.campanhaTitulo = campanhaData.titulo;
                                notification.idclienteusuario = campanhaData.cliente;
                                notification.clienteNome = campanhaData.clienteNome;
                            }
                        }

                        const link = await generateLink({
                            contexto: notification.contexto,
                            idcampanha: notification.idcampanha,
                            campanhaTitulo: notification.campanhaTitulo,
                            idcampanhaitem: notification.idcampanhaitem,
                            idcreator: notification.idcreator,
                            idusuario: notification.idusuario,
                            idclienteusuario: notification.idclienteusuario,
                        });

                        return { ...notification, link };
                    }),
                );

                setLinks(newLinks);
            } catch (error) {
                showError(error);
            }
        };

        if (notifications.length > 0) {
            fetchLinks();
        }
    }, [notifications]);

    const handleLinkClick = (notification) => {
        setSessionStorage({
            contextoAbrir: notification.contexto,
            idcampanha: notification.idcampanha,
            idcampanhaitem: notification.idcampanhaitem,
            idcreator: notification.idcreator,
            idusuario: notification.idusuario,
            idclienteusuario: notification.idclienteusuario,
        });
    };

    return (
        <div>
            <CustomBadge
                badgeContent={notifications.length}
                Icon={NotificationsIcon}
                buttonProps={{ color: 'inherit', onClick: handleClick }}
            />

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {links.length > 0 ? (
                    links.map((notification, index) => {
                        let sender = '';
                        let senderName = '';

                        if (notification.idusuario) {
                            sender = 'FLCO';
                            senderName = notification?.usuario?.nome;
                        } else if (notification.idcreator) {
                            sender = 'Creator';
                            senderName = notification?.creator?.nome;
                        } else if (notification.idclienteusuario) {
                            sender = 'Cliente';
                            senderName = notification?.clienteusuario?.nome;
                        }
                        const campanhaitemTitulo = notification?.campanhaitem?.titulo
                            ? `- ${notification.campanhaitem.titulo}`
                            : '- Sem título';

                        return (
                            <MenuItem key={index} onClick={handleClose}>
                                <a
                                    href={notification.link}
                                    onClick={() => handleLinkClick(notification)}
                                    style={{ textDecoration: 'none', color: 'var(--black-color)', size: '14px' }}
                                >
                                    <small>
                                        Chat de {sender} <strong>({senderName})</strong>
                                        &nbsp; em {notification?.clienteNome}
                                        &nbsp;- {notification?.campanhaTitulo}
                                        &nbsp;
                                        {notification?.campanhaitem ? campanhaitemTitulo : ''}
                                    </small>
                                </a>
                            </MenuItem>
                        );
                    })
                ) : (
                    <MenuItem onClick={handleClose}>Sem notificações</MenuItem>
                )}
            </Menu>
        </div>
    );
};
