import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#f5f5f5',
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? '#444' : '#e0e0e0',
    },
}));

const TabelaEtapas = ({ campanha, campanhaItens }) => {
    const [etapas, setEtapas] = useState([]);
    const [dadosTabela, setDadosTabela] = useState([]);
    const [orderBy, setOrderBy] = useState('nome');
    const [orderDirection, setOrderDirection] = useState('asc');

    const getTotalEntregas = (row) => {
        return Object.values(row.etapas).reduce((total, count) => total + count, 0);
    };

    const getCompletedEntregas = (row) => {
        return row.etapas['completed'] || 0;
    };

    const getCompletionPercentage = (row) => {
        const total = getTotalEntregas(row);
        if (total === 0) return 0;

        const completed = getCompletedEntregas(row);
        return (completed / total) * 100;
    };

    const getAverageProgressPercentage = (row) => {
        if (!etapas || etapas.length === 0) return 0;

        // Encontra todos os itens deste creator
        const creatorItems = campanhaItens.filter((item) => item.creatorIdentify === row.id || item.creator === row.id);

        if (creatorItems.length === 0) return 0;

        let totalProgress = 0;

        creatorItems.forEach((item) => {
            const currentEtapaCode = item.etapa;
            const etapaIndex = etapas.findIndex((e) => e.codigo === currentEtapaCode);
            let itemProgress = 0;

            if (etapaIndex !== -1) {
                if (etapaIndex === 0) {
                    itemProgress = 0;
                } else if (etapaIndex === etapas.length - 1) {
                    itemProgress = 1.0;
                } else {
                    const range = 0.8;
                    const step = range / (etapas.length - 2 || 1);
                    itemProgress = 0.2 + (etapaIndex - 1) * step;
                }
            }

            totalProgress += itemProgress * 100;
        });

        return totalProgress / Math.max(1, creatorItems.length);
    };

    // Gera uma cor entre vermelho e verde com base na porcentagem
    const getGradientColor = (percentage) => {
        // Limitamos a porcentagem entre 0 e 100
        const normalizedPercentage = Math.max(0, Math.min(100, percentage)) / 100;

        // Se for um valor intermediário (entre 20% e 80%), usamos um amarelo mais forte
        if (normalizedPercentage >= 0.2 && normalizedPercentage <= 0.8) {
            return { backgroundColor: 'rgba(255, 193, 7, 0.15)', color: '#000' }; // Amarelo mais forte
        }

        // Valores RGB para vermelho (início) e verde (fim)
        const startRed = 244;
        const startGreen = 67;
        const startBlue = 54;

        const endRed = 76;
        const endGreen = 175;
        const endBlue = 80;

        const red = Math.round(startRed + normalizedPercentage * (endRed - startRed));
        const green = Math.round(startGreen + normalizedPercentage * (endGreen - startGreen));
        const blue = Math.round(startBlue + normalizedPercentage * (endBlue - startBlue));

        return { backgroundColor: `rgba(${red}, ${green}, ${blue}, 0.12)`, color: '#000' };
    };

    const getRowColor = (row) => {
        const averageProgress = getAverageProgressPercentage(row);
        return getGradientColor(averageProgress);
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortData = (data) => {
        return [...data].sort((a, b) => {
            let aValue, bValue;

            if (orderBy === 'nome') {
                aValue = a.nome || '';
                bValue = b.nome || '';
                return orderDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            } else if (orderBy === 'andamento') {
                aValue = getCompletedEntregas(a) / Math.max(1, getTotalEntregas(a));
                bValue = getCompletedEntregas(b) / Math.max(1, getTotalEntregas(b));
            } else if (orderBy === 'porcentagem') {
                aValue = getCompletionPercentage(a);
                bValue = getCompletionPercentage(b);
            } else if (orderBy.startsWith('etapa_')) {
                const etapaCode = orderBy.replace('etapa_', '');
                aValue = a.etapas[etapaCode] || 0;
                bValue = b.etapas[etapaCode] || 0;
            } else {
                return 0;
            }

            if (orderDirection === 'asc') {
                return aValue - bValue;
            } else {
                return bValue - aValue;
            }
        });
    };

    useEffect(() => {
        if (campanhaItens && campanhaItens.length > 0) {
            const primeiroItem = campanhaItens.find((item) => item.etapas && item.etapas.length > 0);
            if (primeiroItem && primeiroItem.etapas) {
                const etapasWorkflow = primeiroItem.etapas.map((etapa) => ({
                    codigo: etapa.etapa,
                    titulo: etapa.etapaTitulo,
                }));
                setEtapas(etapasWorkflow);
            }

            const creatorMap = {};

            campanhaItens.forEach((item) => {
                const creatorId = item.creatorIdentify || 'vazio';

                if (!creatorMap[creatorId]) {
                    const nomeCreator = item.creator ? item.creatorName || 'Creator sem nome' : 'Creator vazio';

                    creatorMap[creatorId] = {
                        id: item.creator || creatorId,
                        nome: nomeCreator,
                        etapas: {},
                    };

                    primeiroItem.etapas.forEach((etapa) => {
                        creatorMap[creatorId].etapas[etapa.etapa] = 0;
                    });
                }
            });

            campanhaItens.forEach((item) => {
                const creatorId = item.creatorIdentify || 'vazio';
                if (creatorMap[creatorId] && item.etapa) {
                    creatorMap[creatorId].etapas[item.etapa] += 1;
                }
            });

            const dadosFormatados = Object.values(creatorMap).sort((a, b) => {
                const nomeA = a.nome || '';
                const nomeB = b.nome || '';
                return nomeA.localeCompare(nomeB);
            });
            setDadosTabela(dadosFormatados);
        }
    }, [campanhaItens]);

    if (!campanhaItens || campanhaItens.length === 0) {
        return <Typography>Nenhum dado disponível para exibição</Typography>;
    }

    return (
        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="tabela de etapas por creator">
                <TableHead>
                    <TableRow>
                        <StyledTableCell onClick={() => handleRequestSort('nome')}>
                            Creator {orderBy === 'nome' && (orderDirection === 'asc' ? '↑' : '↓')}
                        </StyledTableCell>
                        <StyledTableCell align="center" onClick={() => handleRequestSort('andamento')}>
                            Andamento {orderBy === 'andamento' && (orderDirection === 'asc' ? '↑' : '↓')}
                        </StyledTableCell>
                        <StyledTableCell align="center" onClick={() => handleRequestSort('porcentagem')}>
                            % {orderBy === 'porcentagem' && (orderDirection === 'asc' ? '↑' : '↓')}
                        </StyledTableCell>
                        {etapas.map((etapa, index) => (
                            <StyledTableCell
                                key={index}
                                align="center"
                                onClick={() => handleRequestSort(`etapa_${etapa.codigo}`)}
                            >
                                {etapa.titulo}{' '}
                                {orderBy === `etapa_${etapa.codigo}` && (orderDirection === 'asc' ? '↑' : '↓')}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortData(dadosTabela).map((row, rowIndex) => {
                        const rowStyle = getRowColor(row);
                        const percentage = getCompletionPercentage(row);
                        const totalEntregas = getTotalEntregas(row);
                        const completedEntregas = getCompletedEntregas(row);

                        return (
                            <TableRow key={rowIndex} sx={{ ...rowStyle }}>
                                <TableCell component="th" scope="row">
                                    <div>
                                        <span>{row.nome}</span>
                                    </div>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body2">
                                        {completedEntregas}/{totalEntregas}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body2">
                                        {getAverageProgressPercentage(row).toFixed(0)}%
                                    </Typography>
                                </TableCell>
                                {etapas.map((etapa, colIndex) => (
                                    <TableCell key={colIndex} align="center">
                                        {row.etapas[etapa.codigo] || 0}
                                    </TableCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TabelaEtapas;
