/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { AddCircle } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Pagination, Box } from '@mui/material';
import { ButtonFL } from '../../components/ButtonFL';
import styles from '../../_assets/css/generic.module.css';
import { mensagemVazio, TYPE } from '../../data/Interfaces';
import { Get } from '../../data/Verbs';
import { Alert } from '../../components/common/Alert';
import { Loader } from '../../components/Loader';
import { Search } from '../../components/common/Search';
import { ComunidadesList } from './ComunidadesList';
import { CommonMessage } from '../../components/common/CommonMessage';
import { useError } from '../../contexts/ErrorContext';
import { apiGetCommunities } from '../../services/apiGetCommunities';

export function Comunidades() {
    const [query, setQuery] = useState(localStorage.getItem('comunidades-query') || '');
    const [ordenacao, setOrdenacao] = useState('id_desc');
    const [updated, setUpdated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState(mensagemVazio);
    const [comunidades, setComunidades] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);

    const { showError } = useError();

    const handleBusca = (_query) => {
        setQuery(_query);
        setPage(1);
    };

    const handleOrdenar = (campo) => {
        if (ordenacao === `${campo}_asc`) {
            setOrdenacao(`${campo}_desc`);
        } else {
            setOrdenacao(`${campo}_asc`);
        }
    };

    const fetchComunidadesData = async () => {
        setIsLoading(true);
        try {
            const params = {
                sort: ordenacao,
                page: page,
                limit: pageSize,
                type: TYPE.COMMUNITY,
            };

            if (query) {
                params.titulo = query;
            }

            const data = await apiGetCommunities(params);

            if (data.items) {
                setComunidades(data.items || []);
                setTotalItems(data.total || 0);
                setTotalPages(Math.ceil((data.total || 0) / pageSize));
            } else {
                setComunidades(data || []);
                setTotalPages(Math.ceil((data?.length || 0) / pageSize));
                setTotalItems(data?.length || 0);
            }
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchComunidadesData();
    }, [query, ordenacao, updated, page, pageSize]);

    useEffect(() => {
        localStorage.setItem('comunidades-query', query);
    }, [query]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
            {isLoading && <Loader />}
            <div className={styles.container}>
                {message.message && <Alert variant={message.variant} message={message.message} />}

                <Search
                    query={query}
                    handleAdicionar={
                        <ButtonFL
                            component={Link}
                            to="/comunidades/novo"
                            variant="contained"
                            startIcon={<AddCircle />}
                            sx={{ mt: 1, mb: 1 }}
                            textKey="Adicionar"
                        />
                    }
                    handleBusca={handleBusca}
                />

                <CommonMessage
                    message={
                        query == ''
                            ? `Mostrando ${comunidades.length} de ${totalItems} Comunidades`
                            : comunidades.length == 0
                            ? 'Não foram encontradas comunidades cadastradas'
                            : `Listando ${comunidades.length} de ${totalItems} comunidades para a sua busca: ${query}`
                    }
                />

                {comunidades?.length === 0 && query === '' && (
                    <CommonMessage
                        message={
                            'Nenhuma comunidade encontrada. Clique em "Adicionar" para criar a primeira comunidade.'
                        }
                    />
                )}

                <ComunidadesList
                    handleOrdenar={handleOrdenar}
                    comunidades={comunidades}
                    ordenacao={ordenacao}
                    setUpdated={setUpdated}
                />

                {totalPages > 1 && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 3 }}>
                        <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
                    </Box>
                )}
            </div>
        </>
    );
}
