import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Tabs, Tab, Typography, Paper, Button, Stack } from '@mui/material';
import { FileDownload, ArrowBack } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { Get } from '../../data/Verbs';
import { useError } from '../../contexts/ErrorContext';
import DetalhamentoEtapas from './DetalhamentoEtapas';
import TabelaEtapas from './TabelaEtapas';

const CampanhasRelatorios = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { showError } = useError();
    const [campanha, setCampanha] = useState(null);
    const [campanhaItens, setCampanhaItens] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                // Buscar dados da campanha
                const campanhaData = await Get(`${process.env.REACT_APP_API_URL}/campanhas/${id}?noFilter=true`);
                setCampanha(campanhaData);

                // Buscar itens da campanha
                const itensData = await Get(`${process.env.REACT_APP_API_URL}/campanhasItens?campanha=${id}`);
                setCampanhaItens(itensData);
            } catch (error) {
                showError(error);
            } finally {
                setIsLoading(false);
            }
        };

        if (id) {
            fetchData();
        }
    }, [id, showError]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleVoltar = () => {
        // Simplesmente volta para a página anterior
        navigate(-1);
    };

    if (isLoading) {
        return <Typography>Carregando...</Typography>;
    }

    if (!campanha) {
        return <Typography>Campanha não encontrada</Typography>;
    }

    const handleExportReport = () => {
        // Exportar todos os dados para Excel
        if (!campanha || !campanhaItens || campanhaItens.length === 0) return;

        // Preparar dados para exportação
        const workbook = XLSX.utils.book_new();

        // Processar dados de creators
        const creatorsByEntregas = {};
        const creatorCountById = {};

        campanhaItens.forEach((item) => {
            const creatorId = item.creatorIdentify || 'vazio';

            if (!creatorsByEntregas[creatorId]) {
                const nomeCreator = item.creator ? item.creatorName || 'Creator sem nome' : 'Creator vazio';

                creatorsByEntregas[creatorId] = {
                    id: item.creator || creatorId,
                    nome: nomeCreator,
                    totalEntregas: 0,
                };
            }

            creatorsByEntregas[creatorId].totalEntregas += 1;
            creatorCountById[creatorId] = true;
        });

        // Ordenar creators alfabeticamente
        const creatorsArray = Object.values(creatorsByEntregas).sort((a, b) => {
            const nomeA = a.nome || '';
            const nomeB = b.nome || '';
            return nomeA.localeCompare(nomeB);
        });

        // Processar etapas
        const etapasProcessadas = {};
        campanhaItens.forEach((item) => {
            if (!etapasProcessadas[item.etapa]) {
                etapasProcessadas[item.etapa] = {
                    codigo: item.etapa,
                    titulo: item.etapaTitulo || `Etapa ${item.etapa}`,
                    count: 0,
                };
            }
            etapasProcessadas[item.etapa].count += 1;
        });

        // Criar aba principal com layout similar à interface
        const wsMain = XLSX.utils.book_new().Sheets[''];

        // 1. Cabeçalho
        const cabecalho = [
            [{ v: `Relatório: ${campanha.nome || 'N/A'}`, t: 's', s: { font: { bold: true, sz: 14 } } }],
            [{ v: '', t: 's' }],
            [{ v: `Cliente: ${campanha.clienteNome || 'N/A'}`, t: 's' }],
            [{ v: '', t: 's' }],
            [{ v: 'Creators e Entregas', t: 's', s: { font: { bold: true, sz: 12 } } }],
            [
                {
                    v: `Total de Creators: ${Object.keys(creatorCountById).length} | Total de Entregas: ${
                        campanhaItens.length
                    }`,
                    t: 's',
                },
            ],
            [{ v: '', t: 's' }],
        ];

        let ws = XLSX.utils.aoa_to_sheet(cabecalho);

        // 2. Lista de creators
        const creatorsHeader = [
            [
                { v: 'Creator', t: 's', s: { font: { bold: true } } },
                { v: 'Total de entregas', t: 's', s: { font: { bold: true } } },
            ],
        ];

        const creatorsRows = creatorsArray.map((creator) => [
            { v: creator.nome, t: 's' },
            { v: creator.totalEntregas, t: 'n' },
        ]);

        XLSX.utils.sheet_add_aoa(ws, creatorsHeader, { origin: cabecalho.length });
        XLSX.utils.sheet_add_aoa(ws, creatorsRows, { origin: cabecalho.length + 1 });

        // 3. Distribuição por etapas
        const etapasStartRow = cabecalho.length + creatorsRows.length + 3;

        const etapasHeader = [
            [{ v: 'Distribuição por Etapas', t: 's', s: { font: { bold: true, sz: 12 } } }],
            [{ v: '', t: 's' }],
            [
                { v: 'Etapa', t: 's', s: { font: { bold: true } } },
                { v: 'Quantidade', t: 's', s: { font: { bold: true } } },
            ],
        ];

        const etapasData = Object.values(etapasProcessadas).map((etapa) => [
            { v: etapa.titulo, t: 's' },
            { v: etapa.count, t: 'n' },
        ]);

        XLSX.utils.sheet_add_aoa(ws, etapasHeader, { origin: etapasStartRow });
        XLSX.utils.sheet_add_aoa(ws, etapasData, { origin: etapasStartRow + etapasHeader.length });

        // 4. Definir largura das colunas
        ws['!cols'] = [{ width: 30 }, { width: 15 }];

        // Adicionar a planilha ao workbook
        XLSX.utils.book_append_sheet(workbook, ws, 'Relatório');

        // 5. Criar segunda aba com tabela completa de etapas
        // Obter todos as etapas ordenadas
        const etapasOrdenadas = Object.values(etapasProcessadas).sort((a, b) => (a.codigo || 0) - (b.codigo || 0));

        // Criar cabeçalho da tabela
        const tabelaHeader = [['Creator']];
        etapasOrdenadas.forEach((etapa) => tabelaHeader[0].push(etapa.titulo));

        // Criar dados para cada creator
        const tabelaData = [];

        // Mapeamento de creatorId -> etapa -> count
        const creatorEtapaMap = {};

        // Inicializar o mapa
        Object.keys(creatorsByEntregas).forEach((creatorId) => {
            creatorEtapaMap[creatorId] = {};
            etapasOrdenadas.forEach((etapa) => {
                creatorEtapaMap[creatorId][etapa.codigo] = 0;
            });
        });

        // Preencher o mapa com os dados reais
        campanhaItens.forEach((item) => {
            const creatorId = item.creatorIdentify || 'vazio';
            if (creatorEtapaMap[creatorId] && item.etapa) {
                creatorEtapaMap[creatorId][item.etapa] = (creatorEtapaMap[creatorId][item.etapa] || 0) + 1;
            }
        });

        // Criar as linhas da tabela
        creatorsArray.forEach((creator) => {
            const row = [creator.nome];
            const creatorId = creator.id;

            etapasOrdenadas.forEach((etapa) => {
                const countEtapa = creatorEtapaMap[creatorId]?.[etapa.codigo] || 0;
                row.push(countEtapa);
            });

            tabelaData.push(row);
        });

        // Criar planilha de tabela de etapas
        const wsTabelaEtapas = XLSX.utils.aoa_to_sheet([...tabelaHeader, ...tabelaData]);
        XLSX.utils.book_append_sheet(workbook, wsTabelaEtapas, 'Tabela de Etapas');

        // 6. Baixar o arquivo
        XLSX.writeFile(workbook, `relatorio_campanha_${campanha.nome || id}.xlsx`);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                        <Typography variant="h5" gutterBottom>
                            Relatórios: Nivea Meli
                        </Typography>
                        <Typography variant="subtitle1">Cliente: Nivea</Typography>
                    </Box>
                    <Stack direction="row" spacing={1}>
                        <Button
                            variant="contained"
                            startIcon={<ArrowBack />}
                            onClick={handleVoltar}
                            size="small"
                            sx={{
                                mr: 1,
                                bgcolor: 'var(--primary-color)',
                                '&:hover': {
                                    bgcolor: 'var(--primary-light-color)',
                                },
                            }}
                        >
                            Voltar
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<FileDownload />}
                            onClick={handleExportReport}
                            size="small"
                            sx={{
                                bgcolor: 'var(--success-color)',
                                '&:hover': {
                                    bgcolor: 'var(--success-light-color, #2e7d32)',
                                },
                            }}
                        >
                            Exportar relatório
                        </Button>
                    </Stack>
                </Box>
            </Paper>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="Detalhamento de Etapas" />
                    <Tab label="Tabela de Etapas" />
                </Tabs>
            </Box>

            <Box>
                {tabValue === 0 && <DetalhamentoEtapas campanha={campanha} campanhaItens={campanhaItens} />}
                {tabValue === 1 && <TabelaEtapas campanha={campanha} campanhaItens={campanhaItens} />}
            </Box>
        </Box>
    );
};

export default CampanhasRelatorios;
