import { useState, useEffect } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { Clear } from '@mui/icons-material';

export const Search = ({ handleAdicionar, handleBusca, query }) => {
    const [busca, setBusca] = useState(query);

    useEffect(() => {
        setBusca(query);
    }, [query]);

    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={4}>
                    {handleAdicionar}
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="search"
                        label="Busque por Título"
                        name="search"
                        autoComplete="search"
                        value={busca}
                        onChange={(e) => setBusca(e.target.value)}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                handleBusca(busca);
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {busca && (
                                        <IconButton
                                            aria-label="Limpar busca"
                                            size="small"
                                            onClick={() => {
                                                setBusca('');
                                                handleBusca('');
                                            }}
                                        >
                                            <Clear />
                                        </IconButton>
                                    )}
                                    <IconButton aria-label="Buscar" size="small" onClick={() => handleBusca(busca)}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};
