import { ArrowDownward, ArrowUpward, Delete, ModeEdit, Assessment } from '@mui/icons-material';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useError } from '../../contexts/ErrorContext';
import { useAuthStore } from '../../store';
import { apiDeleteCampanha } from '../../services';

export const CampanhasList = ({ campanhas, handleOrdenar, ordenacao, setUpdated, workflows }) => {
    const { showError } = useError();
    const user = useAuthStore((state) => state.user);

    const navigate = useNavigate();

    const editarCampanha = (campanha) => {
        navigate(`/campanhas/editar/${campanha.id}`);
    };

    const excluirCampanha = async (id) => {
        if (!id) return;
        try {
            await apiDeleteCampanha(id);
            if (setUpdated) {
                setUpdated((prev) => !prev);
            }
        } catch (error) {
            showError(error);
        }
    };

    const isOrdenadoAsc = (campo) => {
        return ordenacao === `${campo}_asc`;
    };

    const isOrdenadoDesc = (campo) => {
        return ordenacao === `${campo}_desc`;
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#424242',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const iconStyle = {
        fontSize: '14px',
    };

    if (!workflows || workflows.length === 0) {
        return <p>Nenhum workflow encontrado</p>;
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell
                            onClick={() => handleOrdenar('titulo')}
                            style={{
                                cursor: 'pointer',
                                fontWeight: isOrdenadoAsc('titulo') || isOrdenadoDesc('titulo') ? 'bold' : 'normal',
                            }}
                        >
                            Título
                            {isOrdenadoAsc('titulo') && <ArrowUpward style={iconStyle} />}
                            {isOrdenadoDesc('titulo') && <ArrowDownward style={iconStyle} />}
                        </StyledTableCell>
                        <StyledTableCell
                            onClick={() => handleOrdenar('cliente')}
                            style={{
                                cursor: 'pointer',
                                fontWeight: isOrdenadoAsc('cliente') || isOrdenadoDesc('cliente') ? 'bold' : 'normal',
                            }}
                        >
                            Cliente
                            {isOrdenadoAsc('cliente') && <ArrowUpward style={iconStyle} />}
                            {isOrdenadoDesc('cliente') && <ArrowDownward style={iconStyle} />}
                        </StyledTableCell>
                        <StyledTableCell
                            onClick={() => handleOrdenar('workflow')}
                            style={{
                                cursor: 'pointer',
                                fontWeight: isOrdenadoAsc('workflow') || isOrdenadoDesc('workflow') ? 'bold' : 'normal',
                            }}
                        >
                            Workflow
                            {isOrdenadoAsc('workflow') && <ArrowUpward style={iconStyle} />}
                            {isOrdenadoDesc('workflow') && <ArrowDownward style={iconStyle} />}
                        </StyledTableCell>
                        <StyledTableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {campanhas.map((campanha) => {
                        const workflowName = workflows.find((wf) => wf.id === campanha.workflow)?.nome || 'N/A';
                        return (
                            <TableRow
                                key={campanha.id}
                                className="nowrap"
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                                onClick={() => editarCampanha(campanha)}
                            >
                                <TableCell component="th" scope="row">
                                    {campanha.titulo}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {campanha.clienteNome}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {workflowName}
                                </TableCell>
                                <TableCell align="right">
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            gap: '2px',
                                            border: '1px solid #eaeaea',
                                            borderRadius: '4px',
                                            padding: '2px',
                                            width: 'fit-content',
                                            marginLeft: 'auto',
                                        }}
                                    >
                                        <Button
                                            aria-label="Relatórios Campanha"
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(`/campanhas/relatorios/${campanha.id}`);
                                            }}
                                            sx={{
                                                color: 'var(--success-color)',
                                                minWidth: '32px',
                                                padding: '4px',
                                            }}
                                        >
                                            <Assessment fontSize="small" />
                                        </Button>
                                        <Button
                                            aria-label="Editar Campanha"
                                            size="small"
                                            onClick={() => editarCampanha(campanha)}
                                            sx={{
                                                color: 'var(--primary-color)',
                                                minWidth: '32px',
                                                padding: '4px',
                                            }}
                                        >
                                            <ModeEdit fontSize="small" />
                                        </Button>
                                        {user?.acesso === 1 && (
                                            <Button
                                                aria-label="Excluir Campanha"
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (
                                                        window.confirm(
                                                            'Tem certeza que deseja excluir esta campanha? Esta ação não pode ser desfeita.',
                                                        )
                                                    ) {
                                                        excluirCampanha(campanha.id);
                                                    }
                                                }}
                                                sx={{
                                                    color: 'var(--error-color)',
                                                    minWidth: '32px',
                                                    padding: '4px',
                                                }}
                                            >
                                                <Delete fontSize="small" />
                                            </Button>
                                        )}
                                    </div>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
