import { Get } from '../data/Verbs';

export const apiGetCommunities = async (params) => {
    try {
        if (typeof params === 'number' || (typeof params === 'string' && !isNaN(params))) {
            const communityId = params;
            const url = `${process.env.REACT_APP_API_URL}/comunidades/${communityId}`;
            const response = await Get(url);
            return response;
        }

        const queryParams = { ...(params || {}) };

        if (queryParams.search) {
            queryParams.titulo = queryParams.search;
            delete queryParams.search;
        }

        const queryString = new URLSearchParams(queryParams).toString();
        let url = `${process.env.REACT_APP_API_URL}/comunidades`;

        if (queryString) {
            url += `?${queryString}&noFilter=true`;
        } else {
            url += '?noFilter=true';
        }

        const response = await Get(url);
        return response;
    } catch (error) {
        throw error;
    }
};
