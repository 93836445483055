import { Get } from '../data/Verbs';

export const apiGetWorkflows = async (params) => {
    try {
        const queryString = new URLSearchParams(params).toString();
        const url = `${process.env.REACT_APP_API_URL}/workflows?${queryString}`;
        const response = await Get(url);
        return response;
    } catch (error) {
        throw error;
    }
};
