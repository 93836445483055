/* eslint-disable prettier/prettier */
import { Grid, Box, Stack, Pagination } from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { AddCircle } from '@mui/icons-material';
import { v4 as uuid } from 'uuid';
import { IcampanhasItensStatus, mensagemVazio, showMessage, TYPE } from '../../data/Interfaces';
import { Alert } from '../../components/common/Alert';
import { Filters } from '../../components/Filters';
import { ScopeCreatosCard } from '../../components/ScopeCreatosCard';
import { apiGetCampanhasItens, apiUpdateCampanhaItens } from '../../services';
import { ButtonFL } from '../../components/ButtonFL';
import { useNotificationStore } from '../../store';
import { useError } from '../../contexts/ErrorContext';
import { placeholder, Waiting } from '../../utils';
import { CampanhaItensList } from './CampanhaItensList';
import useFiltroCreatorStore from '../../store/useFiltroCreatorStore';
import { Loader } from '../../components/Loader';
import ButtonImport from '../../components/ButtonImport';

import { apiPostCampanhasItens } from '../../services/apiPostCampanhasItens';
import { apiPostCreatorsBulk } from '../../services/apiPostCreatorsBulk ';

export const CampanhaItens = ({
    campanha,
    creators,
    etapas,
    workflows,
    handleSalvarCampanhaItem,
    handleDuplicarCampanhaItem,
    handleDeletarCampanhaItens,
    handleChangeCreator,
    tab,
    pendingFilterActive,
    updated,
    setUpdated,
}) => {
    const [campanhaItensTotal, setCampanhaItensTotal] = useState([]);

    const [campanhaItens, setCampanhaItens] = useState([]);
    const [filteredCreators, setFilteredCreators] = useState(creators);
    const [message, setMessage] = useState(mensagemVazio);
    const { notifications } = useNotificationStore((state) => ({
        getNotificationsByCreator: state.getNotificationsByCreator,
        notifications: state.notifications,
    }));
    const { showError } = useError();
    const { filtroCreator, setFiltroCreator } = useFiltroCreatorStore();
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    const filteredItems = filtroCreator
        ? campanhaItens?.filter((campaign) =>
            filtroCreator !== 'empty' ? campaign.creator === filtroCreator?.toString() : campaign.creator === null,
        )
        : campanhaItens;

    const handleSetFiltroCreator = (creator) => {
        setIsLoading(true);
        setFiltroCreator(creator);
    };

    const handleAddCreator = async () => {
        setIsLoading(true);

        let campanhaItemModelo = {
            campanha: campanha.id,
            creatorIdentify: uuid(),
            creator: null,
            creatorName: placeholder,
        };

        await handleSalvarCampanhaItem(campanhaItemModelo);
    };

    const handleImportCreator = async (jsonCreators, modelCreatorIdentify) => {
        setIsLoading(true);
        try {
            if (!modelCreatorIdentify) {
                showMessage(
                    {
                        variant: 'error',
                        message: 'É necessário selecionar um creator para servir como modelo.',
                    },
                    setMessage,
                );
                setIsLoading(false);
                return;
            }
            const modelItems = campanhaItens.filter((item) => item.creatorIdentify === modelCreatorIdentify);
            if (modelItems.length === 0) {
                showMessage(
                    { variant: 'error', message: 'Não foi possível encontrar as entregas do creator modelo.' },
                    setMessage,
                );
                setIsLoading(false);
                return;
            }

            const creatorsToImport = jsonCreators.map((creator) => {
                const { nome, email, descricao, instagram, tiktok, briefing } = creator;
                return {
                    nome,
                    email,
                    descricao,
                    instagram,
                    tiktok,
                    escopoBriefing: briefing,
                };
            });

            const importedCreators = await apiPostCreatorsBulk(creatorsToImport);
            const newCampanhaItens = [];

            importedCreators.forEach((creator) => {
                const { id: creatorId, nome } = creator;
                const creatorIdentify = uuid();

                const existingCreator = campanhaItens.find((item) => item.creator === creatorId);
                if (existingCreator) {
                    return;
                }

                modelItems.forEach((modelItem) => {
                    const newItem = {
                        ...modelItem,
                        id: undefined,
                        campanha: campanha.id,
                        campanhaBriefing: campanha.briefing,
                        creatorIdentify,
                        creator: creatorId,
                        creatorName: nome,
                    };

                    newCampanhaItens.push(newItem);
                });
            });

            if (newCampanhaItens.length === 0) {
                showMessage(
                    { variant: 'warning', message: 'Todos os creators já estão cadastrados nesta campanha.' },
                    setMessage,
                );
                setIsLoading(false);
                return;
            }
            await apiPostCampanhasItens(newCampanhaItens);

            showMessage({ variant: 'success', message: 'Creators importados com sucesso!' }, setMessage);
        } catch (error) {
            console.error('Erro ao importar creators:', error);
            showError(error);
        } finally {
            setIsLoading(false);
            setUpdated((prevUpdated) => !prevUpdated);
        }
    };

    const updateCampanhaItem = async (campanhaItem) => {
        try {
            setIsLoading(true);
            await apiUpdateCampanhaItens(campanhaItem.id, campanhaItem);
            setUpdated((prevUpdated) => !prevUpdated);
        } catch (error) {
            showError(error);
        }
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);
            let filtros = {
                page: page,
                limit: pageSize,
            };

            if (tab === 3) {
                filtros.status = `${IcampanhasItensStatus.WAITING}`;
            }
            if (tab === 4) {
                filtros.status = `${IcampanhasItensStatus.WAITING_CLIENT}`;
            }

            if (filtroCreator) {
                filtros.creator = filtroCreator;
            }

            if (campanha.type === TYPE.COMMUNITY) {
                filtros.type = TYPE.COMMUNITY;
            } else if (campanha.type === TYPE.CAMPAIGN) {
                filtros.type = TYPE.CAMPAIGN;
            }

            let dataCampanhaItens = await apiGetCampanhasItens(campanha.id, filtros);

            if (dataCampanhaItens.items) {
                setCampanhaItensTotal(dataCampanhaItens.total || 0);
                setTotalItems(dataCampanhaItens.total || 0);
                setTotalPages(Math.ceil((dataCampanhaItens.total || 0) / pageSize));

                const dataCampanhaItensWithBriefing = dataCampanhaItens.items.map((item) => {
                    return { ...item, campanhaBriefing: campanha.briefing };
                });

                setCampanhaItens(dataCampanhaItensWithBriefing);
            } else {
                setCampanhaItensTotal(dataCampanhaItens);
                setTotalItems(dataCampanhaItens.length || 0);
                setTotalPages(Math.ceil((dataCampanhaItens.length || 0) / pageSize));

                const dataCampanhaItensWithBriefing = dataCampanhaItens.map((item) => {
                    return { ...item, campanhaBriefing: campanha.briefing };
                });

                setCampanhaItens(dataCampanhaItensWithBriefing);
            }
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        const applyFilters = async () => {
            if (filtroCreator) {
                const filtered = creators.filter((creator) => creator.id === filtroCreator);

                setFilteredCreators(filtered);
            } else {
                setFilteredCreators(creators);
            }
        };

        applyFilters();
    }, [filtroCreator, campanhaItens, campanha, creators]);

    useEffect(() => {
        const processAsync = async () => {
            await fetchData();
        };

        processAsync();
    }, [tab, updated, filtroCreator, page, pageSize]);

    const groupByCreator = useMemo(() => {
        if (!campanhaItens.length) return {};

        const groupedItems = filteredItems.reduce((acc, campanhaItem) => {
            const { creatorIdentify, creator, valor, creatorName, escopoBriefing } = campanhaItem;

            if (!acc[creatorIdentify]) {
                acc[creatorIdentify] = {
                    campanhaBriefing: campanha.briefing,
                    escopoBriefing,
                    creator,
                    valor,
                    creatorName,
                    unreadMessages: notifications[creator] || 0,
                    items: [],
                };
            }

            acc[creatorIdentify].items.push(campanhaItem);

            return acc;
        }, {});
        return groupedItems;
    }, [campanhaItens, updated, notifications, filtroCreator]);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box sx={{ bgcolor: 'var(--grey-100-color)' }}>
                    <Grid item xs={12}>
                        {message.message && <Alert variant={message.variant} message={message.message} />}
                    </Grid>
                    <Box>
                        <Stack direction="row" spacing={2} alignItems="center" sx={{ my: 2 }}>
                            <Filters
                                campanhaItens={campanhaItensTotal}
                                creators={creators}
                                setFiltroCreator={handleSetFiltroCreator}
                                filtroCreator={filtroCreator}
                                pendingFilterActive={pendingFilterActive}
                            />
                        </Stack>
                    </Box>
                    {tab === 1 && (
                        <Box sx={{ my: 3, display: 'flex', gap: 2 }}>
                            <ButtonFL
                                startIcon={<AddCircle color="white" />}
                                label="Creator"
                                onClick={handleAddCreator}
                                textKey="Adicionar Creator"
                            />

                            <ButtonImport
                                handleImportCreator={handleImportCreator}
                                groupedItems={groupByCreator}
                                campanhaItens={campanhaItens}
                            />
                        </Box>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                        {campanhaItens.length > 0 ? (
                            tab === 1 ? (
                                <ScopeCreatosCard
                                    groupedItems={groupByCreator}
                                    creators={filteredCreators}
                                    campanha={campanha}
                                    campanhaItens={campanhaItens}
                                    workflows={workflows}
                                    etapas={etapas}
                                    updated={updated}
                                    setUpdated={setUpdated}
                                    handleSalvarCampanhaItem={handleSalvarCampanhaItem}
                                    updateCampanhaItem={updateCampanhaItem}
                                    handleDuplicarCampanhaItem={handleDuplicarCampanhaItem}
                                    handleDeletarCampanhaItens={handleDeletarCampanhaItens}
                                    handleChangeCreator={handleChangeCreator}
                                    tab={tab}
                                />
                            ) : (
                                <Grid container width="100%" justifyContent="left">
                                    <CampanhaItensList
                                        campanhaItens={filteredItems}
                                        campanha={campanha}
                                        workflows={workflows}
                                        handleDeleteCampanhaItem={handleDeletarCampanhaItens}
                                        updateCampanhaItem={updateCampanhaItem}
                                        updated={updated}
                                        setUpdated={setUpdated}
                                        tab={tab}
                                    />
                                </Grid>
                            )
                        ) : null}
                    </Box>
                    {totalPages > 1 && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 3 }}>
                            <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
};
