import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    CardMedia,
    IconButton,
    Menu,
    MenuItem,
    Button,
    CircularProgress,
    Checkbox,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useParams } from 'react-router-dom';
import { useError } from '../../contexts/ErrorContext';
import { Loader } from '../../components/Loader';
import { Get } from '../../data/Verbs';

export const VideosAprovados = () => {
    const [videos, setVideos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedVideoId, setSelectedVideoId] = useState(null);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [isSelectionMode, setIsSelectionMode] = useState(false);
    const { showError } = useError();
    const { id: campanhaId } = useParams();

    useEffect(() => {
        const fetchVideos = async () => {
            setIsLoading(true);
            try {
                const result = await Get(`${process.env.REACT_APP_API_URL}/videos/campanha/${campanhaId}`);
                setVideos(result);
            } catch (error) {
                showError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchVideos();
    }, [campanhaId]);

    const [hoveredVideo, setHoveredVideo] = useState(null);
    const [loadingVideo, setLoadingVideo] = useState(null);
    const handleMouseEnter = (videoId) => {
        setHoveredVideo(videoId);
    };

    const handleMouseLeave = () => {
        setHoveredVideo(null);
    };

    const handleMenuClick = (event, videoId) => {
        setAnchorEl(event.currentTarget);
        setSelectedVideoId(videoId);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedVideoId(null);
    };

    const handleCopyVideoUrl = () => {
        const video = videos.find((v) => v.idVideo === selectedVideoId);
        if (video?.videoFile) {
            navigator.clipboard.writeText(video.videoFile);
        }
        handleMenuClose();
    };

    const handleToggleSelection = (videoId) => {
        setSelectedVideos((prev) => {
            if (prev.includes(videoId)) {
                return prev.filter((id) => id !== videoId);
            } else {
                return [...prev, videoId];
            }
        });
    };

    const handleCopySelectedUrls = () => {
        const urls = videos
            .filter((video) => selectedVideos.includes(video.idVideo))
            .map((video) => video.videoFile)
            .join('\n');
        navigator.clipboard.writeText(urls);
        setSelectedVideos([]);
        setIsSelectionMode(false);
    };

    const handleDownloadVideo = (videoUrl) => {
        const urls = encodeURIComponent(videoUrl);
        window.location.href = `${process.env.REACT_APP_API_URL}/videos/download?urls=${urls}`;
    };

    const handleDownloadSelectedVideos = () => {
        if (selectedVideos.length === 0) return;

        const urls = videos.filter((video) => selectedVideos.includes(video.idVideo)).map((video) => video.videoFile);

        const urlsParam = encodeURIComponent(urls.join(','));
        window.location.href = `${process.env.REACT_APP_API_URL}/videos/download?urls=${urlsParam}`;
        setSelectedVideos([]);
        setIsSelectionMode(false);
    };

    const handleClearSelection = () => {
        setSelectedVideos([]);
        setIsSelectionMode(false);
    };

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4" component="h2">
                    Vídeos aprovados
                </Typography>
                {!isSelectionMode ? (
                    <Button
                        variant="contained"
                        onClick={() => setIsSelectionMode(true)}
                        sx={{ bgcolor: 'var(--primary-color)', '&:hover': { bgcolor: 'var(--primary-light-color)' } }}
                    >
                        Selecionar
                    </Button>
                ) : (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                            variant="contained"
                            startIcon={<ContentCopyIcon />}
                            onClick={handleCopySelectedUrls}
                            disabled={selectedVideos.length === 0}
                            sx={{
                                bgcolor: 'var(--primary-color)',
                                '&:hover': { bgcolor: 'var(--primary-light-color)' },
                            }}
                        >
                            Copiar selecionados
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<GetAppIcon />}
                            onClick={handleDownloadSelectedVideos}
                            disabled={selectedVideos.length === 0}
                            sx={{
                                bgcolor: 'var(--primary-color)',
                                '&:hover': { bgcolor: 'var(--primary-light-color)' },
                            }}
                        >
                            Baixar selecionados
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleClearSelection}
                            sx={{ color: 'var(--primary-color)', borderColor: 'var(--primary-color)' }}
                        >
                            Limpar seleção
                        </Button>
                    </Box>
                )}
            </Box>
            <Grid container spacing={2}>
                {isLoading ? (
                    <Grid item xs={12}>
                        <Loader />
                    </Grid>
                ) : (
                    videos?.map((item) => (
                        <Grid item key={item.idVideo} xs={12} sm={6} md={4}>
                            <Card
                                sx={{
                                    height: '500px',
                                    position: 'relative',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        transform: 'scale(1.02)',
                                        transition: 'transform 0.2s ease-in-out',
                                    },
                                }}
                                onMouseEnter={() => handleMouseEnter(item.idVideo)}
                                onMouseLeave={handleMouseLeave}
                            >
                                {hoveredVideo === item.idVideo ? (
                                    <Box sx={{ height: '100%', position: 'relative' }}>
                                        <CardMedia
                                            component="img"
                                            image={item.frames?.[0]?.frameFileName || ''}
                                            alt={item.titulo || 'Video thumbnail'}
                                            sx={{
                                                height: '100%',
                                                objectFit: 'cover',
                                                display: loadingVideo === item.idVideo ? 'block' : 'none',
                                            }}
                                        />
                                        <video
                                            src={item.videoFile}
                                            autoPlay
                                            muted
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                display: loadingVideo === item.idVideo ? 'none' : 'block',
                                            }}
                                            onLoadStart={() => setLoadingVideo(item.idVideo)}
                                            onCanPlay={() => setLoadingVideo(null)}
                                        />
                                        {loadingVideo === item.idVideo && (
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    backgroundColor: 'rgba(0,0,0,0.5)',
                                                    borderRadius: '50%',
                                                    padding: 1,
                                                }}
                                            >
                                                <CircularProgress size={30} sx={{ color: 'white' }} />
                                            </Box>
                                        )}
                                    </Box>
                                ) : (
                                    <CardMedia
                                        component="img"
                                        image={item.frames?.[0]?.frameFileName || ''}
                                        alt={item.titulo || 'Video thumbnail'}
                                        sx={{
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                )}
                                {isSelectionMode ? (
                                    <Checkbox
                                        checked={selectedVideos.includes(item.idVideo)}
                                        onChange={() => handleToggleSelection(item.idVideo)}
                                        sx={{
                                            position: 'absolute',
                                            top: 8,
                                            right: 8,
                                            backgroundColor: 'rgba(0,0,0,0.5)',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0,0,0,0.7)',
                                            },
                                        }}
                                    />
                                ) : (
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: 8,
                                            right: 8,
                                            backgroundColor: 'rgba(0,0,0,0.5)',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0,0,0,0.7)',
                                            },
                                        }}
                                        onClick={(e) => handleMenuClick(e, item.idVideo)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                )}
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        background: 'linear-gradient(transparent, rgba(0,0,0,0.8))',
                                        p: 2,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography variant="subtitle1" sx={{ color: 'white' }}>
                                        {item.titulo || 'Sem título'}
                                    </Typography>
                                    {selectedVideos.includes(item.idVideo) && (
                                        <Box
                                            sx={{
                                                width: 10,
                                                height: 10,
                                                borderRadius: '50%',
                                                backgroundColor: 'primary.main',
                                            }}
                                        />
                                    )}
                                </Box>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl) && selectedVideoId === item.idVideo}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem onClick={handleCopyVideoUrl}>
                                        <ContentCopyIcon sx={{ mr: 1 }} fontSize="small" />
                                        Copiar link do vídeo
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            handleDownloadVideo(item.videoFile);
                                            handleMenuClose();
                                        }}
                                    >
                                        <GetAppIcon sx={{ mr: 1 }} fontSize="small" />
                                        Download do vídeo
                                    </MenuItem>
                                </Menu>
                            </Card>
                        </Grid>
                    ))
                )}
            </Grid>
        </Box>
    );
};
