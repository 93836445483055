import React, { useState, useEffect, memo } from 'react';
import { Box } from '@mui/material';
import { sortObject } from '../../data/Utils';
import { CampainCard } from '../../components/CampainCard';
import { CampainCardDetailsModal } from '../../components/CampainItemModal/CampainCardDetailsModal';

const MemoizedCampainCard = memo(CampainCard);

export function CampanhaItensList({ campanhaItens, updateCampanhaItem, setUpdated, updated, tab }) {
    const [editModes, setEditModes] = useState([]);
    const [openModal, setOpenModal] = useState({ isOpen: false, type: null });
    const [selectedItem, setSelectedItem] = useState(null);
    const [itemIdCampain, setItemIdCampain] = useState(null);

    useEffect(() => {
        if (Array.isArray(campanhaItens)) {
            setEditModes(new Array(campanhaItens.length).fill(false));
        }
    }, [campanhaItens]);

    const sortedItems = React.useMemo(() => {
        if (!Array.isArray(campanhaItens) || campanhaItens.length === 0) {
            return [];
        }
        return [...campanhaItens].sort((a, b) => {
            if (!a.id || !b.id) return 0;
            return a.id - b.id;
        });
    }, [campanhaItens]);

    const handleOpenModal = (item, modalType) => {
        setSelectedItem(item);
        setOpenModal({ isOpen: true, type: modalType });
    };

    const handleCloseModal = () => {
        setSelectedItem(null);
        setOpenModal({ isOpen: false, type: null });
    };

    const handleOnEditCampanhaItem = (index) => {
        const updatedEditModes = [...editModes];
        updatedEditModes[index] = !editModes[index];
        setEditModes(updatedEditModes);
    };

    const handleOnOpenCampanhaItem = (item) => {
        handleOpenModal(item, 'itemDetail');
    };

    useEffect(() => {
        if (itemIdCampain && Array.isArray(campanhaItens)) {
            const itemUpdated = campanhaItens.find((item) => item.id == itemIdCampain);
            setSelectedItem(itemUpdated);
        }
    }, [campanhaItens, itemIdCampain]);

    if (!Array.isArray(campanhaItens) || campanhaItens.length === 0) {
        return <Box>Nenhum item encontrado.</Box>;
    }

    return (
        <>
            <div className="campanha-items-container">
                {sortedItems.map((item, index) => (
                    <MemoizedCampainCard
                        key={item.id || index}
                        campain={item}
                        handleOpenModal={() => handleOnOpenCampanhaItem(item)}
                        tab={tab}
                    />
                ))}
            </div>

            {selectedItem && (
                <CampainCardDetailsModal
                    open={openModal.isOpen}
                    onClose={handleCloseModal}
                    item={selectedItem}
                    updateCampanhaItem={updateCampanhaItem}
                    type={openModal.type}
                    setItemIdCampain={setItemIdCampain}
                    itemIdCampain={itemIdCampain}
                    setUpdated={setUpdated}
                    updated={updated}
                    campanhaItens={campanhaItens}
                />
            )}
        </>
    );
}
