import { Get } from '../data/Verbs';

export const apiGetCreators = async (params) => {
    try {
        const queryString = new URLSearchParams(params).toString();

        const url = `${process.env.REACT_APP_API_URL}/creators?${queryString}`;

        const response = await Get(url);

        return response;
    } catch (error) {
        throw error;
    }
};
