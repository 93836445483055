import React, { useState } from 'react';
import { Box, Chip, TextField } from '@mui/material';

export const TagsInput = ({ tags = [], setTags, onTagsChange }) => {
    const [inputValue, setInputValue] = useState('');
    const updateTags = (newTags) => {
        if (typeof onTagsChange === 'function') {
            onTagsChange(newTags);
        } else if (typeof setTags === 'function') {
            setTags(newTags);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            e.preventDefault();
            const newTag = inputValue.trim();
            if (!tags.includes(newTag)) {
                updateTags([...tags, newTag]);
            }
            setInputValue('');
        }
    };

    const handleDelete = (tagToDelete) => {
        updateTags(tags.filter((tag) => tag !== tagToDelete));
    };

    return (
        <Box>
            <TextField
                fullWidth
                placeholder="Digite uma categoria e pressione Enter"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={handleKeyPress}
                margin="normal"
            />
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                {tags.map((tag) => (
                    <Chip key={tag} label={tag} onDelete={() => handleDelete(tag)} color="primary" variant="outlined" />
                ))}
            </Box>
        </Box>
    );
};
