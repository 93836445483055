import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Chat } from '@mui/icons-material';
import { Box, Tab, Tabs } from '@mui/material';
import { mensagemVazio, showMessage } from '../../data/Interfaces';
import { Get, Patch, Post } from '../../data/Verbs';
import { Alert } from '../../components/common/Alert';
import { Loader } from '../../components/Loader';
import { CampanhaDados } from './CampanhaDados';
import { CampanhaItens } from './CampanhaItens';
import { VideosAprovados } from './VideosAprovados';
import { ChatModal } from '../../components/ChatModal';
import { useAuthStore, useNotificationStore } from '../../store';
import { handleClearStorage, handleOpenClientFromNotification } from '../../utils/handleStorage.utils';
import { useError } from '../../contexts/ErrorContext';
import { apiGetCampanha, apiGetWorkflows, apiPostCampanhaItem, apiUpdateCampain } from '../../services';
import CustomBadge from '../../components/common/CustomBadge';
import { apiPostDuplicateCampanhaItem } from '../../services/apiPostDuplicateCampanhaItem';
import { apiPostCampaign } from '../../services/apiPostCampaign';
import { apiGetClientes } from '../../services/apiGetClientes';
import { apiGetCreators } from '../../services/apiGetCreators';
import { apiPostAssignCreator } from '../../services/apiPostAssignCreator';

export const Campanha = () => {
    const { id } = useParams();

    const storageContexto = sessionStorage.getItem('storageContexto');
    const storageCampanha = sessionStorage.getItem('storageCampanha');
    const storageCampanhaItem = sessionStorage.getItem('storageCampanhaItem');

    const isValidStorageValue = (value) => value && value !== 'null';

    const getExpandedValue = () => {
        if (id) {
            return 3;
        }

        if (
            isValidStorageValue(storageContexto) &&
            (isValidStorageValue(storageCampanha) || isValidStorageValue(storageCampanhaItem))
        ) {
            return 1;
        }

        return 0;
    };

    const [expanded, setExpanded] = useState(getExpandedValue);

    useEffect(() => {
        setExpanded(getTabFromHash());
    }, [id, storageContexto, storageCampanha, storageCampanhaItem]);

    useEffect(() => {
        const handleHashChange = () => {
            setExpanded(getTabFromHash());
        };
        window.addEventListener('hashchange', handleHashChange);
        return () => window.removeEventListener('hashchange', handleHashChange);
    }, []);

    const [campanha, setCampanha] = useState({});
    const [clientes, setClientes] = useState([]);
    const [creators, setCreators] = useState([]);
    const [workflows, setWorkflows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [updated, setUpdated] = useState(false);
    const [message, setMessage] = useState(mensagemVazio);
    const [chatModalOpen, setChatModalOpen] = useState(false);
    const [clientNotifications, setClientNotifications] = useState(0);
    const user = useAuthStore((state) => state.user);
    const { notifications, fetchNotifications, getNotificationsByContext } = useNotificationStore((state) => ({
        notifications: state.notifications,
        fetchNotifications: state.fetchNotifications,
        getNotificationsByContext: state.getNotificationsByContext,
    }));

    const navigate = useNavigate();
    const { showError } = useError();

    const getTabFromHash = () => {
        const hash = window.location.hash.replace('#', '');
        switch (hash) {
            case 'dados-campanha':
                return 0;
            case 'creators':
                return 1;
            case 'todas-entregas':
                return 2;
            case 'pendencias':
                return 3;
            case 'pendencias-cliente':
                return 4;
            case 'videos-aprovados':
                return 5;
            case 'relatorio':
                return 6;
            default:
                return getExpandedValue();
        }
    };

    const handleChangeTab = (event, newValue) => {
        setExpanded(newValue);
        if (id) {
            const hashMap = {
                0: 'dados-campanha',
                1: 'creators',
                2: 'todas-entregas',
                3: 'pendencias',
                4: 'pendencias-cliente',
                5: 'videos-aprovados',
                6: '',
            };

            if (hashMap[newValue]) {
                window.location.hash = hashMap[newValue];
            }
        }
    };

    const handleSalvarCampanhaItem = async (campanhaItem) => {
        try {
            await apiPostCampanhaItem(campanhaItem);
            setUpdated((prevUpdated) => !prevUpdated);
        } catch (error) {
            showError(error);
        }
    };

    const handleSubmit = async (handleCampanha) => {
        setIsLoading(true);

        if (handleCampanha.id) {
            try {
                await apiUpdateCampain(handleCampanha.id, handleCampanha);
                showMessage({ variant: 'success', message: 'Campanha alterada com sucesso' }, setMessage);
                setUpdated((prevUpdated) => !prevUpdated);
            } catch (error) {
                showError(error);
            }
        } else {
            try {
                const response = await apiPostCampaign(handleCampanha);
                showMessage({ variant: 'success', message: 'Campanha inserida com sucesso' }, setMessage);
                navigate(`/campanhas/editar/${response.id}#creators`);
            } catch (error) {
                showError(error);
            }
        }
    };

    const handleChangeCreator = async ({ creatorIdentify, creator, creatorName, valor, escopoBriefing }) => {
        setIsLoading(true);

        try {
            try {
                const response = await apiPostAssignCreator({
                    campanha: campanha.id,
                    creatorIdentify,
                    creator,
                    valor,
                    creatorName,
                    escopoBriefing,
                });
            } catch (error) {
                showError(error);
            }

            setUpdated((prevUpdated) => !prevUpdated);
        } catch (error) {
            showError(error);
        }
    };

    const handleDuplicarCampanhaItem = async (creatorIdentify, campanhaItens) => {
        setIsLoading(true);

        try {
            const itemsToDuplicate = campanhaItens.filter((item) => item.creatorIdentify === creatorIdentify);
            const idsToDuplicate = itemsToDuplicate.map((item) => item.id);
            await apiPostDuplicateCampanhaItem(idsToDuplicate);
            setUpdated((prevUpdated) => !prevUpdated);
        } catch (error) {
            showError(error);
        }
    };

    const handleDeletarCampanhaItens = async (idsToDelete) => {
        setIsLoading(true);

        try {
            await Post(`${process.env.REACT_APP_API_URL}/campanhasItens/deleteMultiple`, {
                ids: idsToDelete,
            });

            fetchNotifications();

            setUpdated((prevUpdated) => !prevUpdated);
        } catch (error) {
            showError(error);
        }
    };

    const handleChatButtonClick = () => {
        setChatModalOpen(true);
    };

    const handleCloseChat = () => {
        setChatModalOpen(false);
    };

    const renderCampanhaItens = (index, pendingFilterActive = false) => {
        return (
            expanded === index &&
            campanha?.id > 0 &&
            workflows?.length > 0 && (
                <CampanhaItens
                    campanha={campanha}
                    creators={creators}
                    workflows={workflows}
                    handleSalvarCampanhaItem={handleSalvarCampanhaItem}
                    handleDeletarCampanhaItens={handleDeletarCampanhaItens}
                    handleDuplicarCampanhaItem={handleDuplicarCampanhaItem}
                    handleChangeCreator={handleChangeCreator}
                    tab={expanded}
                    pendingFilterActive={pendingFilterActive}
                    updated={updated}
                    setUpdated={setUpdated}
                />
            )
        );
    };

    useEffect(() => {
        const abortController = new AbortController();
        const fetchData = async () => {
            try {
                const [dataWorkflows, dataClientes, dataCreators, notificationContext] = await Promise.all([
                    apiGetWorkflows(),
                    apiGetClientes('sort=nome_asc'),
                    apiGetCreators('sort=nome_asc'),
                    getNotificationsByContext('ADMIN_CLIENTE_CAMPANHA'),
                ]);
                setWorkflows(dataWorkflows);
                setClientes(dataClientes);
                setCreators(dataCreators);
                setClientNotifications(notificationContext);
                if (id) {
                    const dataCampanha = await apiGetCampanha(id);
                    setCampanha(dataCampanha);
                }
            } catch (error) {
                if (!abortController.signal.aborted) {
                    showError(error);
                }
            } finally {
                if (!abortController.signal.aborted) {
                    setIsLoading(false);
                }
            }
        };
        if (handleOpenClientFromNotification()) {
            handleChatButtonClick();
            handleClearStorage();
        }
        fetchData();
        return () => abortController.abort();
    }, [id, updated, getNotificationsByContext, showError]);

    useEffect(() => {
        const fetchNotifications = async () => {
            const clientNotifications = await getNotificationsByContext('ADMIN_CLIENTE_CAMPANHA');
            setClientNotifications(clientNotifications);
        };

        fetchNotifications();
    }, [notifications, getNotificationsByContext]);

    return (
        <>
            {isLoading && <Loader />}

            <>
                <Box sx={{ margin: 2 }}>
                    {message.message && <Alert variant={message.variant} message={message.message} />}

                    <Tabs value={expanded} onChange={handleChangeTab} aria-label="campanha tabs" variant="scrollable">
                        <Tab label="Dados de Campanha" value={0} sx={{ color: 'var(--primary-dark-color)' }} />
                        {id > 0 && [
                            <Tab
                                label="Creators"
                                value={1}
                                key="creators"
                                sx={{ color: 'var(--primary-dark-color)' }}
                            />,
                            <Tab
                                label="Todas as Entregas"
                                value={2}
                                key="entregaveis"
                                sx={{ color: 'var(--primary-dark-color)' }}
                            />,
                            <Tab
                                label="Minhas Pendências"
                                value={3}
                                key="pendentes"
                                sx={{ color: 'var(--primary-dark-color)' }}
                            />,
                            <Tab
                                label="Pendências do cliente"
                                value={4}
                                key="pendenciasCliente"
                                sx={{ color: 'var(--primary-dark-color)' }}
                            />,
                            <Tab
                                label="Vídeos Aprovados"
                                value={5}
                                key="videosAprovados"
                                sx={{ color: 'var(--primary-dark-color)' }}
                            />,
                            <Tab
                                label="Relatório"
                                value={6}
                                key="relatorio"
                                sx={{ color: 'var(--primary-dark-color)' }}
                            />,
                        ]}
                    </Tabs>

                    <TabPanel value={expanded} index={0}>
                        <CampanhaDados _handleSubmit={handleSubmit} campanha={campanha} clientes={clientes} />
                    </TabPanel>

                    <TabPanel value={expanded} index={1}>
                        {renderCampanhaItens(1)}
                    </TabPanel>

                    <TabPanel value={expanded} index={2}>
                        {renderCampanhaItens(2)}
                    </TabPanel>

                    <TabPanel value={expanded} index={3}>
                        {renderCampanhaItens(3, true)}
                    </TabPanel>

                    <TabPanel value={expanded} index={4}>
                        {renderCampanhaItens(4)}
                    </TabPanel>

                    <TabPanel value={expanded} index={5}>
                        <VideosAprovados />
                    </TabPanel>

                    <TabPanel value={expanded} index={6}>
                        {expanded === 6 && (
                            <Box sx={{ height: 0, overflow: 'hidden' }}>{navigate(`/campanhas/relatorios/${id}`)}</Box>
                        )}
                    </TabPanel>
                </Box>

                <ChatModal
                    headerTitle={user.nome}
                    item={campanha}
                    openChat={chatModalOpen}
                    toggleDrawer={handleCloseChat}
                    contexto="ADMIN_CLIENTE_CAMPANHA"
                />

                <CustomBadge
                    badgeContent={clientNotifications}
                    Icon={Chat}
                    isFab
                    fabProps={{
                        color: 'success',
                        'aria-label': 'chat',
                        sx: {
                            position: 'fixed',
                            bottom: 30,
                            right: 30,
                            backgroundColor: 'var(--primary-color)',
                            '&:hover': {
                                backgroundColor: 'var(--primary-light-color)',
                            },
                        },
                        onClick: handleChatButtonClick,
                    }}
                />
            </>
        </>
    );
};

function TabPanel(props) {
    const { sx, children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ ...sx }}>{children}</Box>}
        </div>
    );
}
