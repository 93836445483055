import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Diversity3 } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Typography,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Switch,
    Drawer,
} from '@mui/material';
import { mensagemVazio, showMessage, VISIBILITY, TYPE } from '../../data/Interfaces';
import { useAuthStore } from '../../store';
import { apiDeleteCommunity } from '../../services';
import { Alert } from '../../components/common/Alert';
import { ButtonFL } from '../../components/ButtonFL';
import { Loader } from '../../components/Loader';
import { useError } from '../../contexts/ErrorContext';
import { TagsInput } from '../../components/TagsInput';
import { RankingPeriodos } from './RankingPeriodos';

export const ComunidadeDados = ({ _handleSubmit, comunidade, clientes, isParentLoading }) => {
    const { showError } = useError();
    const user = useAuthStore((state) => state.user);
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false);

    const [form, setForm] = useState({
        cliente: null,
        titulo: '',
        valor: 0,
        aprova: false,
        ranking: false,
        type: TYPE.COMMUNITY,
        visibility: '',
        categorias: [],
        tipos: [],
    });

    const [message, setMessage] = useState(mensagemVazio);
    const [isLoading, setIsLoading] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const invalidFields = handleValidation();
        if (invalidFields.length > 0) {
            const messageText = `Os seguintes campos são obrigatórios: ${invalidFields.join(', ')}`;
            showMessage({ variant: 'warning', message: messageText }, setMessage);
            setIsLoading(false);
            return;
        }

        const handleComunidade = {
            ...(comunidade || {}),
            id: comunidade?.id,
            cliente: form.cliente,
            titulo: form.titulo,
            valor: 0,
            aprova: Boolean(form.aprova),
            ranking: Boolean(form.ranking),
            type: TYPE.COMMUNITY,
            visibility: form.visibility,
            categorias: form.categorias || [],
            tipos: form.tipos || [],
        };

        _handleSubmit(handleComunidade);
        setIsLoading(false);
    };

    const handleValidation = () => {
        const requiredFields = ['cliente', 'titulo', 'visibility'];
        const invalidFields = [];

        requiredFields.forEach((fieldName) => {
            if (!form[fieldName]) {
                invalidFields.push(fieldName.charAt(0).toUpperCase() + fieldName.slice(1));
            }
        });

        return invalidFields;
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    const handleSwitchChange = (field, event) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: event.target.checked,
        }));
    };

    const handleTagsChange = (newTags) => {
        setForm((prevForm) => ({
            ...prevForm,
            categorias: newTags,
        }));
    };

    const handleDeleteComunidade = async () => {
        if (!comunidade?.id) return;

        setIsLoading(true);
        try {
            await apiDeleteCommunity(comunidade.id);
            showMessage({ variant: 'success', message: 'Comunidade excluída com sucesso' }, setMessage);
            navigate('/comunidades');
        } catch (error) {
            showError(error);
            setIsLoading(false);
        }
    };

    const clienteName = useMemo(() => {
        return clientes.find((c) => c.id == form.cliente)?.nome || '';
    }, [clientes, form.cliente]);

    useEffect(() => {
        if (comunidade && Object.keys(comunidade).length > 0) {
            const formData = {
                cliente: comunidade.cliente || '',
                titulo: comunidade.titulo || '',
                valor: 0,
                aprova: Boolean(comunidade.aprova),
                ranking: Boolean(comunidade.ranking),
                type: TYPE.COMMUNITY,
                visibility: comunidade.visibility || '',
                categorias: Array.isArray(comunidade.categorias) ? comunidade.categorias : [],
                tipos: Array.isArray(comunidade.tipos) ? comunidade.tipos : [],
            };

            setForm(formData);
        }
    }, [comunidade]);

    const loading = isLoading || (isParentLoading && clientes?.length === 0);

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    {message.message && <Alert variant={message.variant} message={message.message} />}

                    <form onSubmit={handleSubmit}>
                        <Box sx={{ padding: 2, backgroundColor: 'var(--white-color)' }}>
                            <Box
                                sx={{
                                    margin: 2,
                                    borderRadius: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'var(--white-color)',
                                }}
                            >
                                <Avatar
                                    sx={{
                                        m: 1,
                                        mt: 3,
                                        bgcolor: 'var(--primary-color)',
                                    }}
                                >
                                    <Diversity3 />
                                </Avatar>

                                <Typography component="h1" variant="h5">
                                    {comunidade.id ? 'Adicionar Comunidade' : 'Alterar Comunidade'}
                                </Typography>
                            </Box>

                            <InputLabel id="label-cliente" htmlFor="cliente">
                                Cliente
                            </InputLabel>
                            {comunidade?.id ? (
                                <TextField fullWidth value={clienteName} disabled />
                            ) : (
                                <Select
                                    labelId="label-cliente"
                                    name="cliente"
                                    id="cliente"
                                    label="Cliente"
                                    value={form.cliente || ''}
                                    onChange={handleInputChange}
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (selected) {
                                            return clienteName;
                                        }
                                        return <em style={{ color: 'rgba(0, 0, 0, 0.38)' }}>Selecione um cliente</em>;
                                    }}
                                    fullWidth
                                >
                                    {clientes?.map((cliente) => (
                                        <MenuItem key={cliente?.id} value={cliente?.id}>
                                            {cliente?.nome}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}

                            <InputLabel>Título</InputLabel>
                            <TextField
                                name="titulo"
                                id="titulo"
                                value={form.titulo || ''}
                                autoComplete="Título da Comunidade"
                                onChange={handleInputChange}
                                placeholder="Digite o título da comunidade"
                                fullWidth
                            />

                            <InputLabel id="label-visibility" htmlFor="visibility">
                                Visibilidade
                            </InputLabel>
                            <Select
                                labelId="label-visibility"
                                name="visibility"
                                id="visibility"
                                label="Visibilidade"
                                value={form.visibility || ''}
                                onChange={handleInputChange}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (selected) {
                                        return VISIBILITY[selected];
                                    }
                                    return <em style={{ color: 'rgba(0, 0, 0, 0.38)' }}>Selecione a visibilidade</em>;
                                }}
                                fullWidth
                            >
                                {Object.keys(VISIBILITY).map((key) => (
                                    <MenuItem key={key} value={key}>
                                        {VISIBILITY[key]}
                                    </MenuItem>
                                ))}
                            </Select>

                            <Box sx={{ mt: 2 }}>
                                <InputLabel>Categorias</InputLabel>
                                <TagsInput
                                    tags={form.categorias || []}
                                    onTagsChange={handleTagsChange}
                                    setTags={handleTagsChange}
                                />
                            </Box>

                            <Box sx={{ mt: 2, mb: 2 }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={Boolean(form.aprova)}
                                            onChange={(e) => handleSwitchChange('aprova', e)}
                                        />
                                    }
                                    label="Aprovar Creators"
                                />
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={Boolean(form.ranking)}
                                                onChange={(e) => handleSwitchChange('ranking', e)}
                                            />
                                        }
                                        label="Ranking"
                                    />
                                    {form.ranking && comunidade?.id && (
                                        <Button
                                            size="small"
                                            variant="contained"
                                            sx={{
                                                ml: 2,
                                                bgcolor: 'var(--success-color)',
                                                '&:hover': { bgcolor: 'var(--success-light-color)' },
                                            }}
                                            onClick={() => setOpenDrawer(true)}
                                        >
                                            Abrir Períodos
                                        </Button>
                                    )}
                                </Box>
                            </Box>

                            <Drawer
                                anchor="right"
                                open={openDrawer}
                                onClose={() => setOpenDrawer(false)}
                                sx={{
                                    '& .MuiDrawer-paper': {
                                        width: '600px',
                                    },
                                }}
                            >
                                <RankingPeriodos comunidade={comunidade?.id} />
                            </Drawer>

                            <Box>
                                {!comunidade?.id ? (
                                    <ButtonFL type="submit" fullWidth textKey="Continuar" loading={isLoading} />
                                ) : (
                                    <>
                                        <ButtonFL type="submit" fullWidth textKey="Salvar" loading={isLoading} />
                                        {user?.acesso === 1 && comunidade?.id && (
                                            <ButtonFL
                                                fullWidth
                                                textKey="Excluir Comunidade"
                                                bgcolor="var(--error-color)"
                                                bgColorHover="var(--error-light-color)"
                                                onClick={() => setOpenDeleteDialog(true)}
                                                buttonDisabled={isLoading}
                                            />
                                        )}
                                        <ButtonFL
                                            fullWidth
                                            textKey="Voltar"
                                            bgcolor="var(--warning-color)"
                                            bgColorHover="var(--warning-color)"
                                            onClick={() => navigate('/comunidades')}
                                        />
                                    </>
                                )}
                            </Box>
                        </Box>
                    </form>
                </>
            )}

            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirmar exclusão</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Tem certeza que deseja excluir esta comunidade? Esta ação não pode ser desfeita.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)}>Cancelar</Button>
                    <Button
                        onClick={() => {
                            setOpenDeleteDialog(false);
                            handleDeleteComunidade();
                        }}
                        sx={{
                            color: 'var(--error-color)',
                            '&:hover': { bgcolor: 'rgba(237, 8, 77, 0.1)' },
                        }}
                        autoFocus
                    >
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
