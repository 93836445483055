import { useEffect, useState } from 'react';

import { AddCircle } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { ButtonFL } from '../../components/ButtonFL';

import styles from '../../_assets/css/generic.module.css';
import { Loader } from '../../components/Loader';
import { Search } from '../../components/common/Search';
import { CampanhasList } from './CampanhasList';
import { CommonMessage } from '../../components/common/CommonMessage';
import { useError } from '../../contexts/ErrorContext';
import { apiGetCampanhas, apiGetWorkflows } from '../../services';

export function Campanhas() {
    const [query, setQuery] = useState(localStorage.getItem('campanhas-query') || '');
    const [ordenacao, setOrdenacao] = useState('id_desc');
    const [updated, setUpdated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [campanhas, setCampanhas] = useState([]);
    const [workflows, setWorkflows] = useState([]);

    const { showError } = useError();

    const handleBusca = (_query) => {
        setQuery(_query);
    };

    const handleOrdenar = (campo) => {
        if (ordenacao === `${campo}_asc`) {
            setOrdenacao(`${campo}_desc`);
        } else {
            setOrdenacao(`${campo}_asc`);
        }
    };

    const fetchCampanhasData = async () => {
        try {
            setIsLoading(true);
            const params = {
                sort: ordenacao,
            };

            if (query) {
                params.titulo = query;
            }

            const queryString = new URLSearchParams(params).toString();

            const [campanhasData, workflowsData] = await Promise.all([apiGetCampanhas(queryString), apiGetWorkflows()]);

            setCampanhas(campanhasData || []);
            setWorkflows(workflowsData || []);
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCampanhasData();
    }, [query, ordenacao, updated]);

    useEffect(() => {
        localStorage.setItem('campanhas-query', query);
    }, [query]);

    return (
        <>
            {isLoading && <Loader />}
            <div className={styles.container}>
                <Search
                    query={query}
                    handleAdicionar={
                        <ButtonFL
                            component={Link}
                            to="/campanhas/novo"
                            variant="contained"
                            startIcon={<AddCircle />}
                            sx={{ mt: 1, mb: 1 }}
                            textKey="Adicionar"
                        />
                    }
                    handleBusca={handleBusca}
                />

                <CommonMessage
                    message={
                        query == ''
                            ? `Mostrando ${campanhas.length} Campanhas`
                            : campanhas.length == 0
                            ? 'Não foram encontradas campanhas cadastradas'
                            : `Listando ${campanhas.length} campanhas para a sua busca: ${query}`
                    }
                />

                {!isLoading && (
                    <CampanhasList
                        handleOrdenar={handleOrdenar}
                        campanhas={campanhas}
                        ordenacao={ordenacao}
                        setUpdated={setUpdated}
                        workflows={workflows}
                    />
                )}
            </div>
        </>
    );
}
