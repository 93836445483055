import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { mensagemVazio, showMessage } from '../../data/Interfaces';
import { Alert } from '../../components/common/Alert';
import { Loader } from '../../components/Loader';
import { ComunidadeDados } from './ComunidadeDados';
import { useError } from '../../contexts/ErrorContext';
import { apiGetCommunities, apiUpdateCommunity } from '../../services';
import { apiPostCommunity } from '../../services/apiPostCommunity';
import { apiGetCustumers } from '../../services/apiGetCustomers';

export const Comunidade = () => {
    const { id } = useParams();
    const [comunidade, setComunidade] = useState({});
    const [clientes, setClientes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState(mensagemVazio);

    const navigate = useNavigate();
    const { showError } = useError();

    const fetchCommunityData = useCallback(
        async (communityId) => {
            try {
                const response = await apiGetCommunities(communityId);

                if (Array.isArray(response)) {
                    const matchingCommunity = response.find((comm) => comm.id == communityId);
                    if (matchingCommunity) {
                        setComunidade(matchingCommunity);
                        return matchingCommunity;
                    }
                } else if (response && response.id) {
                    setComunidade(response);
                    return response;
                }
                return null;
            } catch (error) {
                showError(error);
                return null;
            }
        },
        [showError],
    );

    const handleSubmit = async (handleComunidade) => {
        setIsLoading(true);

        try {
            if (handleComunidade.id) {
                await apiUpdateCommunity(handleComunidade.id, handleComunidade);
                showMessage({ variant: 'success', message: 'Comunidade alterada com sucesso' }, setMessage);
                setComunidade(handleComunidade);
            } else {
                const response = await apiPostCommunity(handleComunidade);
                showMessage({ variant: 'success', message: 'Comunidade inserida com sucesso' }, setMessage);
                navigate(`/comunidades/editar/${response.id}`);
            }
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        async function loadData() {
            setIsLoading(true);

            try {
                const [clientsData, communityData] = await Promise.all([
                    apiGetCustumers({ sort: 'nome_asc' }),
                    id ? fetchCommunityData(parseInt(id)) : null,
                ]);

                setClientes(clientsData);
            } catch (error) {
                showError(error);
            } finally {
                setIsLoading(false);
            }
        }

        loadData();
    }, [id, fetchCommunityData, showError]);

    const showLoader = isLoading && (Object.keys(comunidade).length === 0 || clientes.length === 0);

    return (
        <>
            {showLoader && <Loader />}

            <Box
                sx={{
                    margin: { xs: 1, sm: 2 },
                    overflow: 'hidden',
                }}
            >
                {message.message && <Alert variant={message.variant} message={message.message} />}

                <ComunidadeDados
                    _handleSubmit={handleSubmit}
                    comunidade={comunidade}
                    clientes={clientes}
                    isParentLoading={isLoading}
                />
            </Box>
        </>
    );
};
