import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Paper, List, ListItem, ListItemText, Divider, Button } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Get } from '../../data/Verbs';
import { useError } from '../../contexts/ErrorContext';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DetalhamentoEtapas = ({ campanha, campanhaItens }) => {
    const { showError } = useError();
    const [workflow, setWorkflow] = useState(null);
    const [etapasData, setEtapasData] = useState([]);
    const [creatorsData, setCreatorsData] = useState([]);
    const [selectedCreator, setSelectedCreator] = useState(null);

    useEffect(() => {
        const fetchWorkflow = async () => {
            try {
                if (campanha && campanha.workflow) {
                    const data = await Get(`${process.env.REACT_APP_API_URL}/workflows/${campanha.workflow}`);
                    setWorkflow(data);
                }
            } catch (error) {
                showError(error);
            }
        };

        if (campanha) {
            fetchWorkflow();
        }
    }, [campanha, showError]);

    useEffect(() => {
        // Processando dados de etapas para o gráfico
        if (campanhaItens && campanhaItens.length > 0 && workflow) {
            const etapasProcessadas = {};

            if (campanhaItens[0]?.etapas) {
                campanhaItens[0].etapas.forEach((etapa) => {
                    etapasProcessadas[etapa.etapa] = {
                        titulo: etapa.etapaTitulo,
                        count: 0,
                    };
                });
            }

            campanhaItens.forEach((item) => {
                if (etapasProcessadas[item.etapa]) {
                    etapasProcessadas[item.etapa].count += 1;
                }
            });

            setEtapasData(etapasProcessadas);

            const creatorsByEntregas = {};

            campanhaItens.forEach((item) => {
                const creatorId = item.creatorIdentify || 'vazio';

                if (!creatorsByEntregas[creatorId]) {
                    const nomeCreator = item.creator ? item.creatorName || 'Creator sem nome' : 'Creator vazio';

                    creatorsByEntregas[creatorId] = {
                        id: item.creator || creatorId,
                        nome: nomeCreator,
                        totalEntregas: 0,
                    };
                }

                creatorsByEntregas[creatorId].totalEntregas += 1;
            });

            const creatorsArray = Object.values(creatorsByEntregas).sort((a, b) => {
                const nomeA = a.nome || '';
                const nomeB = b.nome || '';
                return nomeA.localeCompare(nomeB);
            });

            setCreatorsData(creatorsArray);
        }
    }, [campanhaItens, workflow]);

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: selectedCreator
                    ? `Distribuição de Entregas por Etapa - ${selectedCreator.nome}`
                    : 'Distribuição de Entregas por Etapa',
            },
        },
        maintainAspectRatio: false,
    };

    const generateColors = (count) => {
        const colors = [
            'rgba(53, 162, 235, 0.7)',
            'rgba(255, 99, 132, 0.7)',
            'rgba(75, 192, 192, 0.7)',
            'rgba(255, 159, 64, 0.7)',
            'rgba(153, 102, 255, 0.7)',
            'rgba(255, 205, 86, 0.7)',
            'rgba(201, 203, 207, 0.7)',
            'rgba(54, 162, 235, 0.7)',
            'rgba(255, 99, 71, 0.7)',
            'rgba(50, 205, 50, 0.7)',
        ];

        return Array(count)
            .fill()
            .map((_, i) => colors[i % colors.length]);
    };

    const etapas = Object.values(etapasData);
    const backgroundColor = generateColors(etapas.length);

    const filterDataByCreator = () => {
        if (!selectedCreator) return etapas;

        const etapasFiltered = {};

        Object.keys(etapasData).forEach((etapaKey) => {
            etapasFiltered[etapaKey] = {
                ...etapasData[etapaKey],
                count: 0,
            };
        });

        campanhaItens.forEach((item) => {
            const creatorId = item.creatorIdentify || 'vazio';

            if (
                (creatorId === selectedCreator.id || item.creator === selectedCreator.id) &&
                etapasFiltered[item.etapa]
            ) {
                etapasFiltered[item.etapa].count += 1;
            }
        });

        return Object.values(etapasFiltered);
    };

    const filteredEtapas = selectedCreator ? filterDataByCreator() : etapas;

    const chartData = {
        labels: filteredEtapas.map((etapa) => etapa.titulo),
        datasets: [
            {
                label: selectedCreator ? `Entregas de ${selectedCreator.nome}` : 'Quantidade de Entregas',
                data: filteredEtapas.map((etapa) => etapa.count),
                backgroundColor,
                borderWidth: 1,
            },
        ],
    };

    const totalEntregas = creatorsData.reduce((total, creator) => total + creator.totalEntregas, 0);
    const totalCreators = creatorsData.length;

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <Paper
                    elevation={2}
                    sx={{
                        p: 2,
                        height: 400,
                        overflow: 'auto',
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Creators e Entregas
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                        Total de Creators: {totalCreators} | Total de Entregas: {totalEntregas}
                    </Typography>
                    <List>
                        {creatorsData.map((creator, index) => (
                            <React.Fragment key={index}>
                                <ListItem
                                    button
                                    onClick={() => setSelectedCreator(creator)}
                                    selected={selectedCreator && selectedCreator.id === creator.id}
                                    sx={{
                                        '&.Mui-selected': {
                                            backgroundColor: 'rgba(25, 118, 210, 0.12)',
                                        },
                                        '&:hover': {
                                            backgroundColor: 'rgba(25, 118, 210, 0.04)',
                                        },
                                        cursor: 'pointer',
                                    }}
                                >
                                    <ListItemText
                                        primary={creator.nome}
                                        secondary={`Total de entregas: ${creator.totalEntregas}`}
                                    />
                                </ListItem>
                                {index < creatorsData.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                        {creatorsData.length === 0 && (
                            <ListItem>
                                <ListItemText primary="Nenhum creator encontrado" />
                            </ListItem>
                        )}
                    </List>
                </Paper>
            </Grid>

            <Grid item xs={12} md={8}>
                <Paper
                    elevation={2}
                    sx={{
                        p: 2,
                        height: 400,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h6">
                            Distribuição por Etapas {selectedCreator ? `- ${selectedCreator.nome}` : ''}
                        </Typography>
                        {selectedCreator && (
                            <Button variant="outlined" size="small" onClick={() => setSelectedCreator(null)}>
                                Limpar seleção
                            </Button>
                        )}
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        {Object.keys(etapasData).length > 0 ? (
                            <Bar
                                options={chartOptions}
                                data={chartData}
                                height="300px"
                                key={selectedCreator ? selectedCreator.id : 'all-creators'}
                            />
                        ) : (
                            <Typography>Nenhum dado disponível para exibição</Typography>
                        )}
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default DetalhamentoEtapas;
