import { Excluir } from '../data/Verbs';

export const apiDeleteCampanha = async (id) => {
    try {
        const response = await Excluir(`${process.env.REACT_APP_API_URL}/campanhas/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
};
