import React, { useRef, useState } from 'react';
import { LibraryAdd, ArrowDropDown } from '@mui/icons-material';
import {
    Menu,
    MenuItem,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    RadioGroup,
    Radio,
    FormControlLabel,
    Typography,
    Button,
} from '@mui/material';
import * as XLSX from 'xlsx';
import { ButtonFL } from '../ButtonFL';

const ButtonImport = ({ handleImportCreator, groupedItems = {}, campanhaItens = [] }) => {
    const fileInputRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedCreatorId, setSelectedCreatorId] = useState('');
    const [parsedData, setParsedData] = useState(null);

    const handleClickImport = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleImportClick = () => {
        handleClose();
        fileInputRef.current.click();
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setParsedData(null);
        setSelectedCreatorId('');
    };

    const handleSelectCreator = (event) => {
        setSelectedCreatorId(event.target.value);
    };

    const handleConfirmImport = () => {
        if (selectedCreatorId && parsedData) {
            handleImportCreator(parsedData, selectedCreatorId);
            handleDialogClose();
        }
    };

    const handleDownloadTemplate = () => {
        handleClose();

        const worksheet = XLSX.utils.aoa_to_sheet([
            ['Modelo de Importação de Creators'],
            ['nome', 'email', 'descricao', 'instagram', 'tiktok', 'briefing'],
            [
                'Nome do Creator',
                'email@exemplo.com',
                'Descrição do Creator',
                '@instagram',
                '@tiktok',
                'Briefing do Creator',
            ],
        ]);

        const wscols = [
            { wch: 20 }, // nome
            { wch: 25 }, // email
            { wch: 30 }, // descricao
            { wch: 15 }, // instagram
            { wch: 15 }, // tiktok
            { wch: 30 }, // briefing
        ];
        worksheet['!cols'] = wscols;

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Add Creators');

        XLSX.writeFile(workbook, 'modelo_importacao_creators.xlsx');
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });

                    const sheetName = workbook.SheetNames.includes('Add Creators')
                        ? 'Add Creators'
                        : workbook.SheetNames[0];

                    if (!sheetName) {
                        alert('Planilha inválida: não contém nenhuma aba.');
                        return;
                    }

                    const worksheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                    if (!jsonData || jsonData.length < 3) {
                        alert(
                            'Planilha inválida: não contém dados suficientes. É necessário ter o título, cabeçalhos e pelo menos uma linha de dados.',
                        );
                        return;
                    }

                    const dataRows = jsonData.slice(2);

                    const formattedData = dataRows
                        .map((row) => {
                            if (!row || !row[0]) {
                                return null;
                            }

                            let obj = {
                                nome: row[0] || '',
                                email: row[1] || '',
                                telefone: row[2] || '',
                                descricao: row[3] || '',
                                instagram: row[4] || '',
                                tiktok: row[5] || '',
                                briefing: row[6] || '',
                                layouts: [],
                            };

                            for (let i = 7; i < row.length; i += 4) {
                                if (row[i] || row[i + 1] || row[i + 2] || row[i + 3]) {
                                    let layout = {
                                        Tipo: row[i] || '',
                                        ID: row[i + 1] || '',
                                        Qtd: row[i + 2] || '',
                                        Titulo: row[i + 3] || '',
                                    };
                                    obj.layouts.push(layout);
                                }
                            }

                            return obj;
                        })
                        .filter((row) => row !== null);

                    if (formattedData.length === 0) {
                        alert('Nenhum creator válido foi encontrado na planilha.');
                        return;
                    }

                    // Verificar se há creators disponíveis para servir como modelo
                    if (Object.keys(groupedItems).length === 0) {
                        alert('É necessário ter pelo menos um creator cadastrado para servir como modelo.');
                        return;
                    }

                    // Armazenar os dados e abrir a caixa de diálogo para seleção do creator modelo
                    setParsedData(formattedData);
                    setOpenDialog(true);
                } catch (error) {
                    console.error('Erro ao processar a planilha:', error);
                    alert('Erro ao processar a planilha. Verifique se o formato é compatível.');
                }
            };

            reader.onerror = () => {
                alert('Erro ao ler o arquivo.');
            };

            reader.readAsArrayBuffer(file);
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ButtonFL
                    startIcon={<LibraryAdd color="white" />}
                    endIcon={<ArrowDropDown />}
                    label="Creator"
                    onClick={handleClickImport}
                    textKey="Importar Creators"
                />
            </Box>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleImportClick}>Importar Creators</MenuItem>
                <MenuItem onClick={handleDownloadTemplate}>Baixar Modelo</MenuItem>
            </Menu>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept=".xlsx, .xls, .csv"
                onChange={handleFileChange}
            />

            {/* Diálogo para seleção do creator modelo */}
            <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="md" fullWidth>
                <DialogTitle>Selecione um Creator para usar como modelo</DialogTitle>
                <DialogContent>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        Selecione um Creator existente para usar como modelo. As entregas deste Creator serão duplicadas
                        para os novos Creators importados.
                    </Typography>

                    {Object.keys(groupedItems).length > 0 ? (
                        <RadioGroup value={selectedCreatorId} onChange={handleSelectCreator}>
                            {Object.keys(groupedItems).map((creatorIdentify) => (
                                <FormControlLabel
                                    key={creatorIdentify}
                                    value={creatorIdentify}
                                    control={<Radio />}
                                    label={
                                        <Typography>
                                            <strong>
                                                {!groupedItems[creatorIdentify].creatorName ||
                                                groupedItems[creatorIdentify].creatorName.trim() === ''
                                                    ? 'Vazio'
                                                    : groupedItems[creatorIdentify].creatorName}
                                            </strong>
                                            {groupedItems[creatorIdentify].items
                                                ? ` (${groupedItems[creatorIdentify].items.length} entregas)`
                                                : ''}
                                        </Typography>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    ) : (
                        <Typography color="error">Não há Creators disponíveis para usar como modelo.</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancelar</Button>
                    <Button
                        onClick={handleConfirmImport}
                        disabled={!selectedCreatorId}
                        variant="contained"
                        color="primary"
                    >
                        Importar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ButtonImport;
