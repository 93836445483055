import { List, ListItemButton, ListItemIcon, ListItemText, Tooltip, useTheme, Box } from '@mui/material';
import { menus } from '../data/Menus';
import { useAuthStore } from '../store';
import { useWindowSize } from '../hooks';

const ListItems = ({ open }) => {
    const user = useAuthStore((state) => state.user);
    const theme = useTheme();
    const { width } = useWindowSize();
    const isMobile = width < 769;
    const listItemIconStyleClosed = {
        padding: isMobile ? '6px' : '6px',
        minWidth: 'unset',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        margin: '0 auto',
    };

    const listItemIconStyle = {
        minWidth: '35px',
        display: 'flex',
        alignItems: 'center',
    };

    const defaultTextStyle = {
        fontWeight: 'normal',
        color: 'inherit',
    };

    const hoverStyles = {
        '&:hover': {
            backgroundColor: 'var(--primary-color)',
        },
        display: 'flex',
        justifyContent: !open ? 'center' : 'flex-start',
        alignItems: 'center',
        padding: isMobile ? '10px 6px' : '8px 16px',
        minHeight: isMobile ? '48px' : '40px',
        borderRadius: isMobile ? '4px' : '0',
        margin: isMobile ? '1px 2px' : '0',
    };

    const hoverTextStyle = {
        '&:hover': {
            fontWeight: 'bold',
            color: 'var(--white-color)',
        },
    };

    return (
        <List
            component="nav"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: isMobile ? '4px 0' : 0,
                overflow: 'auto',
                maxHeight: isMobile ? 'calc(100vh - 180px)' : 'unset',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
                '& .MuiListItemButton-root': {
                    transition: 'all 0.2s ease',
                },
            }}
        >
            {menus.map(
                (menu, index) =>
                    user.acesso >= menu.accessLevel && (
                        <ListItemButton key={index} to={menu.link} sx={hoverStyles}>
                            {open === true ? (
                                <ListItemIcon style={listItemIconStyle}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {menu.icon && <menu.icon />}
                                    </Box>
                                </ListItemIcon>
                            ) : (
                                <Tooltip
                                    title={menu.title}
                                    placement="right"
                                    arrow
                                    enterTouchDelay={isMobile ? 0 : 700}
                                    leaveTouchDelay={isMobile ? 1500 : 300}
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: 'var(--primary-color)',
                                                color: 'var(--white-color)',
                                                fontWeight: 'bold',
                                                fontSize: isMobile ? '14px' : '12px',
                                                p: isMobile ? '8px 16px' : '6px 12px',
                                                borderRadius: '4px',
                                                maxWidth: isMobile ? '200px' : '180px',
                                                textAlign: 'center',
                                                [theme.breakpoints.down('sm')]: {
                                                    marginLeft: '16px',
                                                },
                                                '&::before': {
                                                    content: '""',
                                                    position: 'absolute',
                                                    left: '-6px',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    width: 0,
                                                    height: 0,
                                                    borderTop: '6px solid transparent',
                                                    borderBottom: '6px solid transparent',
                                                    borderRight: '6px solid var(--primary-color)',
                                                },
                                            },
                                        },
                                        popper: {
                                            sx: {
                                                zIndex: 1500,
                                                '& .MuiTooltip-tooltip': {
                                                    [theme.breakpoints.down('sm')]: {
                                                        marginLeft: '16px !important',
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                >
                                    <ListItemIcon style={listItemIconStyleClosed}>
                                        {menu.icon && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    fontSize: isMobile ? '1.1rem' : '1rem',
                                                }}
                                            >
                                                <menu.icon />
                                            </Box>
                                        )}
                                    </ListItemIcon>
                                </Tooltip>
                            )}
                            {open === true && (
                                <ListItemText
                                    primary={menu.title}
                                    sx={{
                                        ...defaultTextStyle,
                                        ...hoverTextStyle,
                                    }}
                                />
                            )}
                        </ListItemButton>
                    ),
            )}
        </List>
    );
};

export default ListItems;
