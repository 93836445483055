import { Get } from '../data/Verbs';

export const apiGetClientes = async (params) => {
    try {
        const queryString = new URLSearchParams(params).toString();
        const response = await Get(`${process.env.REACT_APP_API_URL}/clientes?${queryString}`);
        return response;
    } catch (error) {
        throw error;
    }
};
