import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { Get } from '../../data/Verbs';
import { mensagemVazio, showMessage, TYPE } from '../../data/Interfaces';
import { useAuthStore } from '../../store';
import { apiDeleteCampanha, apiGetWorkflows } from '../../services';
import { Alert } from '../../components/common/Alert';
import { ButtonFL } from '../../components/ButtonFL';
import { Loader } from '../../components/Loader';
import { TextEditor } from '../../components/TextEditor';
import { useError } from '../../contexts/ErrorContext';

export const CampanhaDados = ({ _handleSubmit, campanha, clientes }) => {
    const { showError } = useError();
    const user = useAuthStore((state) => state.user);
    const navigate = useNavigate();

    const [form, setForm] = useState({
        cliente: null,
        workflow: null,
        titulo: '',
        valor: '',
        aprova: false,
        template: '',
        type: TYPE.CAMPAIGN,
        categorias: [],
        tipos: [],
    });

    const decodedBriefing = decodeURIComponent(campanha?.briefing ?? '');
    const [cliente, setCliente] = useState('');
    const [workflow, setWorkflow] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [message, setMessage] = useState(mensagemVazio);
    const [isLoading, setIsLoading] = useState(true);
    const [editorContent, setEditorContent] = useState(decodedBriefing);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const encodedBriefing = encodeURIComponent(editorContent);
        setIsLoading(true);

        const invalidFields = handleValidation();
        if (invalidFields.length > 0) {
            const messageText = `Os seguintes campos são obrigatórios: ${invalidFields.join(', ')}`;
            showMessage({ variant: 'warning', message: messageText }, setMessage);
            return;
        }

        const handleCampanha = {
            ...campanha,
            cliente: form.cliente,
            workflow: form.workflow,
            titulo: form.titulo,
            briefing: encodedBriefing,
            valor: form.valor,
            aprova: form.aprova,
            template: form.template,
            type: TYPE.CAMPAIGN,
            categorias: form.categorias,
            tipos: form.tipos,
        };

        _handleSubmit(handleCampanha);
        setIsLoading(false);
    };

    const handleValidation = () => {
        const requiredFields = ['cliente', 'workflow', 'titulo', 'template'];
        const invalidFields = [];

        requiredFields.forEach((fieldName) => {
            if (!form[fieldName]) {
                invalidFields.push(fieldName.charAt(0).toUpperCase() + fieldName.slice(1));
            }
        });

        return invalidFields;
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setForm({
            ...form,
            [name]: value,
        });

        if (name === 'cliente') {
            setCliente(value);
        }
    };

    const handleValorChange = (values) => {
        const { value } = values;
        setForm({ ...form, valor: value });
    };

    const handleSwitchChange = (field, event) => {
        setForm({
            ...form,
            [field]: event.target.checked,
        });
    };

    const handleTagsChange = (newTags) => {
        setForm({
            ...form,
            categorias: newTags,
        });
    };

    const fetchWorkflow = async () => {
        try {
            const data = await apiGetWorkflows('sort=nome_asc');
            setWorkflow(data);
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchTemplates = async () => {
        try {
            const data = await Get(`${process.env.REACT_APP_API_URL}/templates?cliente=${cliente}&sort=titulo_asc`);
            setTemplates(data);
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteCampanha = async () => {
        if (!campanha?.id) return;

        setIsLoading(true);
        try {
            await apiDeleteCampanha(campanha.id);
            showMessage({ variant: 'success', message: 'Campanha excluída com sucesso' }, setMessage);

            navigate('/campanhas');
        } catch (error) {
            showError(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (campanha) {
            setForm({
                cliente: campanha?.cliente || '',
                workflow: campanha?.workflow || null,
                titulo: campanha?.titulo || '',
                valor: campanha?.valor,
                template: campanha?.template || '',
                type: 'CAMPAIGN',
                categorias: campanha?.categorias || [],
            });

            campanha?.cliente && setCliente(campanha?.cliente);
        }
    }, [campanha]);

    useEffect(() => {
        fetchTemplates();
        fetchWorkflow();
    }, [cliente]);

    const loading = isLoading || clientes?.length === 0 || workflow?.length === 0;

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    {message.message && <Alert variant={message.variant} message={message.message} />}

                    <form onSubmit={handleSubmit}>
                        <Box sx={{ padding: 2, backgroundColor: 'var(--white-color)' }}>
                            <InputLabel id="label-cliente" htmlFor="cliente">
                                Cliente
                            </InputLabel>
                            {!campanha?.cliente ? (
                                <Select
                                    labelId="label-cliente"
                                    name="cliente"
                                    id="cliente"
                                    label="Cliente"
                                    value={form.cliente}
                                    onChange={handleInputChange}
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (selected) {
                                            return clientes.find((c) => c.id == selected)?.nome;
                                        }
                                        return <em style={{ color: 'rgba(0, 0, 0, 0.38)' }}>Selecione um cliente</em>;
                                    }}
                                    fullWidth
                                >
                                    {clientes?.map((cliente) => (
                                        <MenuItem key={cliente?.id} value={cliente?.id}>
                                            {cliente?.nome}
                                        </MenuItem>
                                    ))}
                                </Select>
                            ) : (
                                <>
                                    <TextField
                                        fullWidth
                                        value={clientes.find((c) => c.id == form.cliente)?.nome}
                                        disabled
                                    />
                                </>
                            )}

                            {!campanha?.workflow ? (
                                <>
                                    <InputLabel htmlFor="workflow">Workflow</InputLabel>
                                    <Select
                                        name="workflow"
                                        id="workflow"
                                        label="Workflow"
                                        value={form.workflow ?? ''}
                                        onChange={handleInputChange}
                                        displayEmpty
                                        renderValue={(selected) => {
                                            if (selected) {
                                                return workflow.find((w) => w.id == selected)?.nome;
                                            }
                                            return (
                                                <em style={{ color: 'rgba(0, 0, 0, 0.38)' }}>Selecione um workflow</em>
                                            );
                                        }}
                                        fullWidth
                                    >
                                        {workflow.map((_workflow) => (
                                            <MenuItem key={_workflow.id} value={_workflow.id}>
                                                {_workflow.nome}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </>
                            ) : (
                                <>
                                    <InputLabel>Workflow</InputLabel>
                                    <TextField
                                        fullWidth
                                        value={workflow?.find((w) => w.id == form?.workflow)?.nome}
                                        disabled
                                    />
                                </>
                            )}

                            {!campanha?.id ? (
                                <>
                                    <InputLabel htmlFor="template">Template</InputLabel>
                                    <Select
                                        name="template"
                                        id="template"
                                        label="template"
                                        value={form.template ?? ''}
                                        onChange={handleInputChange}
                                        displayEmpty
                                        renderValue={(selected) => {
                                            if (selected) {
                                                return templates.find((t) => t.id == selected)?.titulo;
                                            }
                                            return (
                                                <em style={{ color: 'rgba(0, 0, 0, 0.38)' }}>Selecione um template</em>
                                            );
                                        }}
                                        fullWidth
                                    >
                                        {templates.map((_template) => (
                                            <MenuItem key={_template.id} value={_template.id}>
                                                {_template.titulo}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </>
                            ) : (
                                <>
                                    <InputLabel>Template</InputLabel>
                                    <TextField
                                        fullWidth
                                        label={templates.find((t) => t.id === form.template)?.titulo}
                                        disabled
                                    />
                                </>
                            )}

                            <InputLabel>Título</InputLabel>
                            <TextField
                                name="titulo"
                                id="titulo"
                                value={form.titulo}
                                autoComplete="Título da Campanha"
                                onChange={handleInputChange}
                                placeholder="Digite o título da campanha"
                                fullWidth
                            />
                            <Box>
                                <Typography variant="body1" sx={{ py: 2, color: 'var(--grey-900-color)' }}>
                                    Briefing
                                </Typography>
                                <TextEditor
                                    setEditorContent={setEditorContent}
                                    editorContent={editorContent}
                                    placeholder="Digite o briefing da campanha aqui..."
                                />
                            </Box>
                            <InputLabel>Budget da Campanha</InputLabel>
                            <NumericFormat
                                value={form.valor}
                                customInput={TextField}
                                displayType={'input'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                decimalScale={2}
                                fixedDecimalScale
                                prefix={'R$ '}
                                onValueChange={handleValorChange}
                                fullWidth
                                placeholder="R$ 0,00"
                            />

                            {!campanha?.id ? (
                                <ButtonFL type="submit" fullWidth textKey="Continuar" />
                            ) : (
                                <>
                                    <ButtonFL type="submit" fullWidth textKey="Salvar" />
                                    {user?.acesso === 1 && campanha?.id && (
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            sx={{
                                                bgcolor: 'var(--error-color)',
                                                color: 'white',
                                                '&:hover': { bgcolor: 'var(--error-light-color)' },
                                            }}
                                            onClick={() => setOpenDeleteDialog(true)}
                                        >
                                            Excluir Campanha
                                        </Button>
                                    )}
                                </>
                            )}
                            <ButtonFL
                                fullWidth
                                textKey="Voltar"
                                bgcolor="var(--warning-color)"
                                bgColorHover="var(--warning-color)"
                                onClick={() => navigate('/campanhas')}
                            />
                        </Box>
                    </form>
                </>
            )}

            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirmar exclusão</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Tem certeza que deseja excluir esta campanha? Esta ação não pode ser desfeita.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)}>Cancelar</Button>
                    <Button
                        onClick={() => {
                            setOpenDeleteDialog(false);
                            handleDeleteCampanha();
                        }}
                        sx={{
                            color: 'var(--error-color)',
                            '&:hover': { bgcolor: 'rgba(237, 8, 77, 0.1)' },
                        }}
                        autoFocus
                    >
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
