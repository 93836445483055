import { Post } from '../data/Verbs';

export const apiPostCampaign = async (campaign) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/campanhas`, campaign);
        return response;
    } catch (error) {
        throw error;
    }
};
